import React from "react";

import './index.scss'
import { FaPlay } from "react-icons/fa"
import ai from "../../../public/aiempower"

import Cover from "./Cover";

export default class Simple extends React.Component {

  constructor(props) {
    super(props);  

    this.state = {
      valid : true,
      selected : null,
      filters : [],
      code : "",
      description : "",
      filterDesc : "",
      //prompt : props.prompt?props.prompt:"Use EMA Fast and EMA Slow to create a strategy. With 1000 per trade. Only stocks in Semi-conductor sector",
    } 

    this.generateStrategyDesc = this.generateStrategyDesc.bind(this)
    this.updatevalue = this.updatevalue.bind(this)
    this.run = this.run.bind(this)

    this.changeStrategy = this.changeStrategy.bind(this)

    this.initialCode = this.initialCode.bind(this)
    this.getCode = this.getCode.bind(this)
    this.getDesc = this.getDesc.bind(this)
    this.update = this.update.bind(this)

  }

  componentDidMount() {
    this.initialCode() 
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
      //console.log(  this.props.selected.region )
      if( (state.selected !== props.selected) || ( this.props.selected !== props.selected)  ) {
        
        if ( this.props.selected !== props.selected) {
          this.initialCode() 
        } else {
          this.initialCode()
        }
      }
  }

  async initialCode() {
    var obj ={}    
    const { selected } = this.props
    if( selected && selected['strategy_list'] && selected['strategy_list'].length > 0 ) { 
        obj.selected = selected
        obj.code = selected['strategy_list'][0]["code"]
        obj.ocode = selected['strategy_list'][0]["code"]
        obj.description = selected["description"]
        //if( selected['region'] === "usa" ) {
        //  obj['prompt'] = "Use EMA Fast and EMA Slow to create a strategy. With 1000 per trade. Only stocks in Semi-conductor sector"
        //} else {
        //  obj['prompt'] = "Use EMA Fast and EMA Slow to create a strategy. With 10000 per trade. Only stocks in Semi-conductor sector"
        //}
        this.setState(obj, ()=>{
        })
    } else {
        obj.selected = null
        obj.code = null
        obj.ocode = null
        obj.description = ""
        obj.filterDesc = ""
        this.setState(obj)
    }
    
  }

  updatevalue(obj, cb) {
    this.setState( obj, cb )
  }

  getCode() {
    return this.state.code
  }

  getDesc() {
    return this.state.description
  }

  update(res){
    const {updateEditorvalue} = this.props
    try {
      this.setState({
        obj : res,
        code : res['code'],
        description : res["description"]
      }, ()=>{
        this.generateStrategyDesc( res['prodcut_screeners'] )
      })
    } catch(e) {
      updateEditorvalue( {
        isLoading : false
      } )
    }
  }

  async run() {
    //const {prompt} = this.state
    const { language, updateEditorvalue, prompt } = this.props
    var body = {"msg" : prompt, "lang" : language}
    updateEditorvalue({
      isLoading : true
    })
    try {
      let res = await ai.ai_botfolio( { "msg" : prompt , "lang" : language}   )
      if( Object.keys( res).length > 0 ){
        updateEditorvalue({
        }, () => {
          this.update(res)
        })
      } else {
        window.alert("Fail to Create")
        updateEditorvalue({
          isLoading : false
        })
      }
    } catch(e) {
      window.alert("Fail to Create")
      updateEditorvalue({
        isLoading : false
      })
    }
  }

  async generateStrategyDesc(filter){
    const {selected, updateEditorvalue} = this.props

    if(selected["region"]==="usa") {

      var desc=[], filters=[]
      for (var f of filter) {
        desc.push( f["screnner_description"] )
        var a = f['filter']
        if(  typeof(a.value)==="object"  ) {
          a['value'] = a['value'].join(",")
        } else {
          a['value'] = a['value'].toString()
        }
        filters.push(  a  )
      }

      var body = {
        "filters" : filters,
        "region" : selected["region"]
      }

      try {
        let data = await ai.screener(  body  )
        var syms = data[ data.length -1  ]['value'].map( (v) => v["symbol"] )
        this.setState({
          filterDesc : desc.join("\n")
        }, ()=>{
          var f = [{key: "symbol", opr: "in", type: "symbol", value: syms.join(",")  }]
          this.changeStrategy(  "filters" ,  f   )
          updateEditorvalue( {
            isLoading : false
          } )
        })
      } catch(e){
        window.alert("Fail to Get the List.")
        updateEditorvalue( {
          isLoading : false
        } )
      }
    } else {
      updateEditorvalue( {
        isLoading : false
      } )
    }

  }

  changeStrategy(key, value) {
    var {selected, updatevalue} = this.props
    selected['strategy_list'][0][key] = value
    updatevalue({
      selected : selected
    })
  }

  render() {
    const { selected, description, filterDesc } = this.state
    const { i18n, language, updateEditorvalue, create, prompt} = this.props
    if(selected) {
      return (
        <div className={["Simple"].join(" ")}>

            <div className={["Wrapper", "Center"].join(" ")}>
              <div className={["Holder", "Column"].join(" ")}>
                <div className={["Title"].join(" ")}> {"Strategy Description".toUpperCase()} </div>
                <p className={["Desc"].join(" ")}> {description!==""?description:"NO DESCRIPTION"} </p>
              </div>
              <div className={["Holder", "Column"].join(" ")}>
                <div className={["Title"].join(" ")}> {"screener reasoning".toUpperCase()} </div>
                <p className={["Desc"].join(" ")}> {filterDesc!==""?filterDesc:"NO DESCRIPTION"} </p>
              </div>
            </div>





            <div className={["Row", "Bottom"].join(" ")}>
              <textarea
                className={["Prompt"].join(" ")}
                placeholder="Type your Prompt"
                value={ prompt } onChange={(e)=>{
                updateEditorvalue({
                  prompt : e.target.value
                })
              }}/>
              <div className="Btn Column" onClick={ ()=>{
                this.run()
              } }>
                <FaPlay className="Fa"/>
              </div>
            </div>
        </div>
      )
    } else {
      return (
        <Cover 
          {...this.props}
        />
      )
    }
  }
}


