import React from "react";

import './index.scss'

import GService from "../../../Garlican/GarlicanLibrary/gservice.js";
//import GService  from './gservice'
import Opcode from './opcode'
//import apis from '../../../Garlican/GarlicanLibrary/apis'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import CustomNode from './customNode.js'
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';


var options = {}, dictionary=[], tempOpt=[], isPaste = false

const templates = window.templates


export default class IDE extends React.Component {

  constructor(props) {
    super(props);  
    this.editor= React.createRef()
    
    options = {
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      highlightSelectedWord:true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
    }

    this.state = {
      code : "",
      ocode : "",
      selected : null,
    } 

    this.editor = React.createRef()
    this.updateDistionary = this.updateDistionary.bind(this)
    this.updateAC = this.updateAC.bind(this)
    this.initialCode = this.initialCode.bind(this)
    this.change = this.change.bind(this)

    this.getCode = this.getCode.bind(this)
    //console.log( props.code )
  }

  componentDidMount() {
    this.initialCode()
  }

  updateDistionary() {
    if(  this.editor ) {
      if( this.editor.current ) {
        const customMode = new CustomNode();
        this.editor.current.editor.getSession().setMode(customMode);
        dictionary=[]
        var obj = {}, i = 0;
        var cases = GService.defaults.concat( GService.exit  )
        for (i in cases) {
          obj={}
          obj.name = GService.defaults[i]
          obj.value = GService.defaults[i]
          obj.caption = GService.defaults[i] + " [" + GService.defaults[i] + "]"
          obj.meta = null
          obj.score = 1000  
          dictionary.push( obj )
        }
        for (i in GService.structure) {
          if(GService.structure[i].display) {
            obj={}
            obj.name = i
            obj.value = GService.structure[i].stm
            obj.caption = i + " [" + (i) + "]"
            obj.meta = null
            obj.score = 1000  
            dictionary.push( obj )
          }
        }

        for (i in  window.func) {
          
          if( window.func[i].display) {
            obj={}
            obj.name =  window.func[i].name
            obj.value =  window.func[i].format
            obj.caption =  window.func[i].name + " [" +  window.func[i]['desc'][ this.props.lang] + "]"
            obj.meta = null
            obj.score = 1000 
            dictionary.push( obj )
          }
        }

        for (i in Opcode.op_func ) {
          obj={}
          obj.name = i
          obj.value = Opcode.op_func[i].value
          obj.caption = i + " [" +  Opcode.op_func[i]['desc'][ this.props.lang ] + "]"
          obj.meta = null
          obj.score = 1000  
          dictionary.push( obj )
        }
        this.updateAC( dictionary )
      }
    }
    //this.setTempOptions()
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, prevState) {
    var _this = this
    
    if( (prevState.selected !== props.selected) || ( this.props.selected !== props.selected)  ) {
      //console.log(  this.props.selected  + "/" + props.selected + "/" + prevState.selected )
      if ( this.props.selected !== props.selected) {
        this.initialCode() 
      } else {
        this.initialCode()
      }
    }

  }

  initialCode() {
    var obj ={}    
    const { selected } = this.props
    if( selected && selected['strategy_list'] && selected['strategy_list'].length > 0 ) { 
        obj.selected = selected
        obj.code = selected['strategy_list'][0]["code"]
        obj.ocode = selected['strategy_list'][0]["code"]
        this.setState(obj)
    } else {
        obj.selected = null
        obj.code = null
        obj.ocode = null
        this.setState(obj)
    }
    
  }

  updateAC(dict) {
    setCompleters([{
      getCompletions: function(editor, session, pos, prefix, callback) {
          callback(null, dict);
      },
    }]);
  }

  change(e) {
    this.setState( {
      code : e,
    })
  }

  getCode() {
    return this.state.code
  }

  render() {
    const { selected, code } = this.state

    if( selected ) {
      return (
        <div className={[ 'IDE'  ].join(' ')} 
          onMouseLeave={
            (e) => {
            }
        } >
          { (this.props.isCode) && code &&
            <AceEditor
              width="auto"
              selectionStyle={"line"}
              wrapEnabled={ true }
              fontSize={14}
              onLoad={ (e) => {
                var _this = this
                setTimeout(
                  (e)=>{
                    _this.updateDistionary()
                  }, 300
                )
              }}
              ref={this.editor} 
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              mode="python"
              theme="solarized_dark"
              className={ ['Textarea'].join(' ') } 
              onChange={ (e)=>{
                this.change( e )
              } }
              onBlur={
                (e)=> {
                  if( this.state.code !== this.state.ocode  ) {
                    //this.props.onBlur( this.state.code ,  this.props.selectedBoard  )
                  }
                }
              }
              name="GARLICAN"
              value={ this.state.code }  
              editorProps={{ $blockScrolling: false }}
              setOptions={this.state.options}
              enableLiveAutocompletion={true}
            />
          }
          { 
            !code &&
              <div className={['IDE',"I-Cover"].join(" ")}>
                { "Strategy has no code." }
              </div>
          }
        </div>
      );
    } else {
      return (
        <div className={['IDE',"I-Cover"].join(" ")}>
          { "Please Pick a Strategy to start with" }
        </div>
      )
    }
  }
}


