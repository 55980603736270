"use strict";
//import React, {Component} from 'react';

var Opcode= {

operator:{
	">" : { 'eng': "greater than" , 'zhcn':"大于", 'zhtc':"大於"  },
	"<" : { 'eng': "smaller than" , 'zhcn':"小于", 'zhtc':"小於"  },
	">=" : { 'eng': "greater than or equals" , 'zhcn':"大或等于", 'zhtc':"大或等於"  },
	"<=" : { 'eng': "smaller than or equals" , 'zhcn':"小或等于", 'zhtc':"小或等於"  },
	"!=" : { 'eng': "not equals to" , 'zhcn':"不等于", 'zhtc':"不等於"  },
	"==" : { 'eng': "alsolutely equals to" , 'zhcn':"绝对等于", 'zhtc':"絶對等於"  },
},
op_func:{
	"+" : { 'code': 'BINARY_ADD' , 'value' : '(close + close)', 'desc' : {'eng': "(close adds close)" , 'zhcn':"(close 加 close)", 'zhtc':"(close 加 close)" }  , 'name' : {'eng': "+" , 'zhcn':"加", 'zhtc':"加" }  },
	"-" : { 'code': 'BINARY_SUBTRACT' , 'value' : '(close - close)', 'desc' : { 'eng': "(close minus close)" , 'zhcn':"（close 减 close）", 'zhtc':"（close 减 close）"  } , 'name' : {'eng': "-" , 'zhcn':"减", 'zhtc':"减" }  },
	"*" : { 'code': 'BINARY_MULTIPLY', 'value' : '(close * close)', 'desc' : { 'eng': "(close multipies with close)" , 'zhcn':"（close 乘以 close）", 'zhtc':"（close 乘以 close）"  } , 'name' : {'eng': "X" , 'zhcn':"乘", 'zhtc':"乘" }  },
	"/" : { 'code': 'BINARY_TRUE_DIVIDE' , 'value' : '(close / close)', 'desc' : { 'eng': "(close divided by close)" , 'zhcn':"（close 除以 close）", 'zhtc':"（close 除以 close）"  } , 'name' : {'eng': "/" , 'zhcn':"除", 'zhtc':"除" } },
	">" : { 'code': 'COMPARE_OP', 'value' : '(close > open)', 'desc' : { 'eng': "greater than" , 'zhcn':"大于", 'zhtc':"大於"  } , 'name' : { 'eng': ">" , 'zhcn':"大于", 'zhtc':"大於"  }  },
	"<" : { 'code': 'COMPARE_OP', 'value' : '(close < open)', 'desc' : { 'eng': "smaller than" , 'zhcn':"小于", 'zhtc':"小於"  }, 'name' : { 'eng': "<" , 'zhcn':"小于", 'zhtc':"小於"  } },
	">=" : { 'code': 'COMPARE_OP', 'value' : '(close >= open)', 'desc' : { 'eng': "greater than or equals" , 'zhcn':"大或等于", 'zhtc':"大或等於"  }, 'name' : { 'eng': ">=" , 'zhcn':"大或等于", 'zhtc':"大或等於"  } },
	"<=" : { 'code': 'COMPARE_OP', 'value' : '(close <= open)', 'desc' : { 'eng': "smaller than or equals" , 'zhcn':"小或等于", 'zhtc':"小或等於"  }, 'name' : { 'eng': "<=" , 'zhcn':"小或等于", 'zhtc':"小或等於"  } },
	"!=" : { 'code': 'COMPARE_OP', 'value' : '(close != open)', 'desc' : { 'eng': "not equals to" , 'zhcn':"不等于", 'zhtc':"不等於"  }, 'name' : { 'eng': "!=" , 'zhcn':"不等于", 'zhtc':"不等於"  } },
	"==" : { 'code': 'OMPARE_OP', 'value' : '(close == open)', 'desc' : { 'eng': "alsolutely equals to" , 'zhcn':"绝对等于", 'zhtc':"絶對等於"  } , 'name' : { 'eng': "==" , 'zhcn':"绝对等于", 'zhtc':"絶對等於"  } },
	"|" : { 'code': 'BINARY_OR', 'value' : '(close | close)', 'desc' : { 'eng': "(Either condition A or condition B happens)" , 'zhcn':"(条件A或条件B其中之一发生)", 'zhtc':"(條件A或條件B其中之一發生)"  }, 'name' : { 'eng': "or" , 'zhcn':"其中发生", 'zhtc':"其中發生" } },
	"&" : { 'code': 'BINARY_AND', 'value' : '(close & close)', 'desc' : { 'eng': "Both condition A and condition B happens" , 'zhcn':"条件A和条件B同时发生", 'zhtc':"條件A和條件B同時發生"  } , 'name' : { 'eng': "and" , 'zhcn':"同吋发生", 'zhtc':"同時發生" } },
},
elses: {
	"else" : { value: "else:", desc:{ 'eng': "Else" , 'zhcn':"如以上条件不符, 则:", 'zhtc':"如以上條件不符, 則:"  } },
	"elif" : { value: "elif (close>0):", 'desc': { 'eng': "Else if ( close > 0) happens:" , 'zhcn':"如以上条件不符, 若 ( close > 0 ) 發生則:", 'zhtc':"如以上条件不符, 若 ( close > 0 ) 发生则:" } },
	"elifn" : { value: "elif not (close>0):", 'desc': { 'eng': "Else if ( close > 0 ) not happens:" , 'zhcn':"如以上条件不符, 若 ( close > 0 ) 不發生則:", 'zhtc':"如以上条件不符, 若 ( close > 0 ) 不发生则:"  }} ,
},
breaks: {
	"else" : { value: "break", desc:{ 'eng': "Breaks loop" , 'zhcn':"終止循环", 'zhtc':"终止循環"  } },
},
operations : {
'BEFORE_ASYNC_WITH': {'code' : 52, 'gython': "__aenter_", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  } },
'BINARY_ADD': {'code' : 23, 'gython': "( <v1> + <v2> )", 'var_len':2, 'operator' : "+",
'desc': { 'eng': "( <v1>  adds  <v2> )" , 'zhcn':"( <v1> 加 <v2> )", 'zhtc':"( <v1> 加 <v2> )"  }},
'BINARY_AND': {'code' : 64, 'gython': "( <v1> & <v2> )", 'var_len':2, 'operator' : "&",
'desc': { 'eng': "(both  <v1>  and  <v2>  happens)" , 'zhcn':"( <v1> 和 <v2> 同時发生)", 'zhtc':"( <v1> 和 <v2> 同時發生)"  }},
'BINARY_FLOOR_DIVIDE': {'code' : 26, 'gython': "( <v1> // <v2> )", 'var_len':2, 'operator' : "//",
'desc': { 'eng': "( <v1>  divided by  <v2>  with rounded)" , 'zhcn':"( <v1> 除以 <v2> 并向上舍入)", 'zhtc':" <v1> 除以 <v2> 並向上舍入"  }},
'BINARY_LSHIFT': {'code' : 62 , 'gython': "( <v1> << <v2> )", 'var_len':2, 'operator' : "<<",
'desc': { 'eng': "( <v1>  left shifted  <v2> )" , 'zhcn':"( <v1> 左移 <v2> )", 'zhtc':"( <v1> 左移 <v2> )"  }}, 
'BINARY_MATRIX_MULTIPLY': {'code' : 16, 'gython': "( <v1> @ <v2> )", 'var_len':2, 'operator' : "@",
'desc': { 'eng': "( <v1>  matrix multipies  <v2> )" , 'zhcn':"（ <v1> 以矩阵法乘上以 <v2> ）", 'zhtc':" <v1> 以矩陣法乘上以 <v2> ）"  }},
'BINARY_MODULO': {'code' : 22, 'gython': "( <v1> % <v2> )", 'var_len':2, 'operator' : "%",
'desc': { 'eng': "(reminder of  <v1>  divided by  <v2> )" , 'zhcn':"( <v1> 除以 <v2> 的馀数)", 'zhtc':" <v1> 除以 <v2> 的餘數"  }},
'BINARY_MULTIPLY': {'code' : 20, 'gython': "( <v1> * <v2> )", 'var_len':2, 'operator' : "*",
'desc': { 'eng': "( <v1>  multipies with  <v2> )" , 'zhcn':"( <v1> 乘以 <v2> )", 'zhtc':"( <v1> 乘以 <v2> )"  }},
'BINARY_OR': {'code' : 66, 'gython': "( <v1> | <v2> )", 'var_len':2, 'operator' : "|",
'desc': { 'eng': "(Either  <v1>  or  <v2>  happens)" , 'zhcn':"( <v1> 或 <v2> 其中之一发生)", 'zhtc':"( <v1> 或 <v2> 其中之一發生)"  }},
'BINARY_POWER': {'code' : 19, 'gython': "( <v1> ** <v2> )", 'var_len':2, 'operator' : "**",
'desc': { 'eng': "( <v1>  powered by  <v2> )" , 'zhcn':"( <v1> 的 <v2> 次乘方)", 'zhtc':"( <v1> 的 <v2> 次乘方)"  }},
'BINARY_RSHIFT': {'code' : 63, 'gython': "( <v1> >> <v2> )", 'var_len':2, 'operator' : ">>",
'desc': { 'eng': "( <v1>  right shifted  <v2> )" , 'zhcn':"( <v1> 右移 <v2> )", 'zhtc':"( <v1> 右移 <v2> )"  }},
'BINARY_SUBSCR': {'code' : 25, 'gython': " <v1> [ <v2> ]", 'var_len':2,
'desc': { 'eng': "( <v1>  with the previous  <v2> -th data)" , 'zhcn':"( <v1> 中 <v2> 周期之前的数据)", 'zhtc':"( <v1> 中 <v2> 周期之前的數據)"  }},
'BINARY_SUBSCR_SPLICE': {'code' : 25, 'gython': " <v1> [ <v2> : <v3> ]", 'var_len':2,
'desc': { 'eng': "( <v1>  with the previous  <v2> to <v3> -th data)" , 'zhcn':"( <v1> 中 <v2> 至 <v3> 周期之前的数据)", 'zhtc':"( <v1> 中 <v2> 至 <v3> 周期之前的數據)"  }},
'BINARY_SUBTRACT': {'code' : 24, 'gython': "( <v1> - <v2> )", 'var_len':2, 'operator' : "-",
'desc': { 'eng': "( <v1>  minus  <v2> )" , 'zhcn':"( <v1> 减 <v2> )", 'zhtc':"( <v1> 减 <v2> )"  }},
'BINARY_TRUE_DIVIDE': {'code' : 27, 'gython': "( <v1> / <v2> )", 'var_len':2, 'operator' : "/",
'desc': { 'eng': "( <v1>  divided by  <v2> )" , 'zhcn':"( <v1>  除以  <v2> )", 'zhtc':"( <v1>  除以  <v2> )"  }},
'BINARY_XOR': {'code' : 65, 'gython': "( <v1>^<v2> )", 'var_len':2, 'operator' : "^",
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BREAK_LOOP': {'code' : 80, 'gython': "break", 'var_len':0,
'desc': { 'eng': "(breaks loop)" , 'zhcn':"(終止循环)", 'zhtc':"(终止循環)"  }},
'BUILD_CONST_KEY_MAP': {'code' : 156, 'gython': "{}", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_LIST': {'code' : 103, 'gython': "[]", 'var_len':0,
'desc': { 'eng': "(Build array with )" , 'zhcn':"(创建炬阵包括)", 'zhtc':"(創建炬陣包括)"  }},
'BUILD_LIST_UNPACK': {'code' : 149, 'gython': "[*x, *y, *z]", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_MAP': {'code' : 105, 'gython': "{}", 'var_len':0,
'desc': { 'eng': "(Build object with )" , 'zhcn':"(创建{}包括)", 'zhtc':"(創建{}包括)"  }},
'BUILD_MAP_UNPACK': {'code' : 150, 'gython': "{**x, **y, **z}", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_MAP_UNPACK_WITH_CALL': {'code' : 151, 'gython': "f(**x, **y, **z)", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_SET': {'code' : 104, 'gython': "{}", 'var_len':0,
'desc': { 'eng': "(Build object with )" , 'zhcn':"(创建{}包括)", 'zhtc':"(創建{}包括)"  }},
'BUILD_SET_UNPACK': {'code' : 153, 'gython': "{*x, *y, *z}", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
/*'BUILD_SLICE': {'code' : 133, 'gython': "slice(TOS2, TOS1, TOS)", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},*/
'BUILD_SLICE': {'code' : 133, 'gython': " ( <v1> : <v2> ) ", 'var_len':2,
'desc': { 'eng': " from <v1> to <v2>  " , 'zhcn':" <v1> 至 <v2>  ", 'zhtc':" <v1> 至 <v2> "  }},
'BUILD_STRING': {'code' : 157, 'gython': "x", 'var_len':0,
'desc': { 'eng': "(define  <v1>  as string)" , 'zhcn':"(建立 <v1> 字元)", 'zhtc':"(建立 <v1> 字元)"  }},
'BUILD_TUPLE': {'code' : 102, 'gython': "0", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_TUPLE_UNPACK': {'code' : 152, 'gython': "(*x, *y, *z)", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'BUILD_TUPLE_UNPACK_WITH_CALL': {'code' : 158, 'gython': "f(*x, *y, *z)", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'CALL_FUNCTION': {'code' : 131, 'gython': "function", 'var_len':-1,
'desc': { 'eng': "(Function (<f>) with variables : <v> )" , 'zhcn':"(函数 (<f>) 並输入 : <v> )", 'zhtc':"(函数 (<f>) 並輸入 : <v> )"  }},
'CALL_FUNCTION_EX': {'code' : 142, 'gython': "function", 'var_len':-1,
'desc': { 'eng': "(Function (<f>) with variables : <v> )" , 'zhcn':"(函数 (<f>) 並输入 : <v> )", 'zhtc':"(函数 (<f>) 並輸入 : <v> )"  }},
'CALL_FUNCTION_KW': {'code' : 141, 'gython': "function", 'var_len':-1,
'desc': { 'eng': "(Function (<f>) with variables : <v> )" , 'zhcn':"(函数 (<f>) 並输入 : <v> )", 'zhtc':"(函数 (<f>) 並輸入 : <v> )"  }},
'COMPARE_OP': {'code' : 107, 'gython': "( <v1> <opr> <v2> )", 'var_len':2,
'desc': { 'eng': "( <v1>  <opr>  <v2> )" , 'zhcn': "( <v1>  <opr>  <v2> )", 'zhtc':"( <v1>  <opr>  <v2> )"  }},
'CONTINUE_LOOP': {'code' : 119, 'gython': "for_looping", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'DELETE_ATTR': {'code' : 96, 'gython': "del  <v1>.<v2> ", 'var_len':2,
'desc': { 'eng': "delete  <v1> . <v2> " , 'zhcn':"移除  <v1> 中的 <v2> ", 'zhtc':"移除  <v1> 中的 <v2> "  }},
'DELETE_DEREF': {'code' : 138, 'gython': "del  <v1> ", 'var_len':1,
'desc': { 'eng': "delete  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'DELETE_FAST': {'code' : 126, 'gython': "del  <v1> ", 'var_len':1,
'desc': { 'eng': "delete  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'DELETE_GLOBAL': {'code' : 98, 'gython': "del  <v1> ", 'var_len':1,
'desc': { 'eng': "delete  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'DELETE_NAME': {'code' : 91, 'gython': "del  <v1> ", 'var_len':1,
'desc': { 'eng': "delete  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'DELETE_SUBSCR': {'code' : 61, 'gython': "del  <v1>[v2]", 'var_len':2,
'desc': { 'eng': "delete  <v1> [ <v2> ]" , 'zhcn':"移除  <v1> 中第 <v2> 的数据", 'zhtc':"移除  <v1> 中第 <v2> 的數據"  }},
'DUP_TOP': {'code' : 4, 'gython':"dup" , 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'DUP_TOP_TWO': {'code' : 5, 'gython':"dup", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'END_FINALLY': {'code' : 88, 'gython':"end" , 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'EXTENDED_ARG': {'code' : 144, 'gython':"argv" , 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'FORMAT_VALUE': {'code' : 155, 'gython':"fmt_spec", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'FOR_ITER': {'code' : 93, 'gython':"for  <v1>  in  <v2> :", 'var_len':0,
'desc': { 'eng': "Stores at  <v1> , loops  <v2> " , 'zhcn':"变量为 <v1>   <v2> 的循环:", 'zhtc':"變量為 <v1>   <v2> 的循環:"  }},
'GET_AITER': {'code' : 50, 'gython':" <v1> .__aiter__()", 'var_len':1,
'desc': { 'eng': " <v1> .__aiter__()" , 'zhcn':" <v1> .__aiter__()", 'zhtc':" <v1> .__aiter__()"  }},
'GET_ANEXT': {'code' : 51, 'gython':"PUSH(get_awaitable( <v1> .__anext__()))", 'var_len':1,
'desc': { 'eng': "PUSH(get_awaitable( <v1> .__anext__()))" , 'zhcn':"PUSH(get_awaitable( <v1> .__anext__()))", 'zhtc':"PUSH(get_awaitable( <v1> .__anext__()))"  }},
'GET_AWAITABLE': {'code' : 73, 'gython':" <v1> .__aiter__()", 'var_len':1,
'desc': { 'eng': " <v1> .__aiter__()" , 'zhcn':" <v1> .__aiter__()", 'zhtc':" <v1> .__aiter__()"  }},
'GET_ITER': {'code' : 68, 'gython':"iter( <v1> )", 'var_len':1,
'desc': { 'eng': "iter( <v1> )" , 'zhcn':"iter( <v1> )", 'zhtc':"iter( <v1> )"  }},
'GET_YIELD_FROM_ITER': {'code' : 69, 'gython':"iter( <v1> )", 'var_len':1,
'desc': { 'eng': "iter( <v1> )" , 'zhcn':"iter( <v1> )", 'zhtc':"iter( <v1> )"  }},
'IMPORT_FROM': {'code' : 109, 'gython':"from  <v1>  import  <v2> ", 'var_len':2,
'desc': { 'eng': "from  <v1>  import  <v2> " , 'zhcn':"从 <v1> 库中输入 <v2> 模组", 'zhtc':"從 <v1> 庫中输入 <v2> 模组"  }},
'IMPORT_NAME': {'code' : 108, 'gython':"import  <v1> ", 'var_len':1,
'desc': { 'eng': "import  <v1> " , 'zhcn':"输入 <v1> 库", 'zhtc':"输入 <v1> 庫"  }},
'IMPORT_NAME_2': {'code' : 108, 'gython':"import  <v1> as <v2> ", 'var_len':1,
'desc': { 'eng': "import  <v1> as <v2> " , 'zhcn':"输入 <v1> 库定名为 <v2> ", 'zhtc':"输入 <v1> 庫定名為 <v2> "  }},
'IMPORT_STAR': {'code' : 84, 'gython':"from  <v1>  import *", 'var_len':1,
'desc': { 'eng': "from  <v1>  import all" , 'zhcn':"从 <v1> 库中输入所有模组", 'zhtc':"從 <v1> 庫中输入所有模组"  }},
'INPLACE_ADD': {'code' : 55, 'gython': "( <v1> + <v2> )", 'var_len':2, 'operator' : "+",
'desc': { 'eng': "( <v1>  adds  <v2> )" , 'zhcn':"( <v1> 加 <v2> )", 'zhtc':"( <v1> 加 <v2> )"  }},
'INPLACE_AND': {'code' : 77, 'gython': "( <v1> & <v2> )", 'var_len':2, 'operator' : "&",
'desc': { 'eng': "(both  <v1>  and  <v2>  happens)" , 'zhcn':"( <v1> 和 <v2> 同时发生)", 'zhtc':"( <v1> 和 <v2> 同時發生)"  }},
'INPLACE_FLOOR_DIVIDE': {'code' : 28, 'gython': "( <v1> // <v2> )", 'var_len':2, 'operator' : "//",
'desc': { 'eng': "( <v1>  divided by  <v2>  with rounded)" , 'zhcn':"( <v1> 除以 <v2> 并向上舍入)", 'zhtc':" <v1> 除以 <v2> 並向上舍入"  }},
'INPLACE_LSHIFT': {'code' : 75, 'gython': "( <v1> << <v2> )", 'var_len':2, 'operator' : "<<",
'desc': { 'eng': "( <v1>  left shifted  <v2> )" , 'zhcn':"( <v1> 左移 <v2> )", 'zhtc':"( <v1> 左移 <v2> )"  }}, 
'INPLACE_MATRIX_MULTIPLY': {'code' : 17, 'gython': "( <v1> @ <v2> )", 'var_len':2, 'operator' : "@",
'desc': { 'eng': "( <v1>  matrix multipies  <v2> )" , 'zhcn':"（ <v1> 以矩阵法乘上以 <v2> ）", 'zhtc':" <v1> 以矩陣法乘上以 <v2> ）"  }},
'INPLACE_MODULO': {'code' : 59, 'gython': "( <v1> % <v2> )" , 'var_len':2, 'operator' : "%",
'desc': { 'eng': "(reminder of  <v1>  divided by  <v2> )" , 'zhcn':"( <v1> 除以 <v2> 的馀数)", 'zhtc':" <v1> 除以 <v2> 的餘數"  }},
'INPLACE_MULTIPLY': {'code' : 57, 'gython': "( <v1> * <v2> )" , 'var_len':2, 'operator' : "*",
'desc': { 'eng': "( <v1>  multipies with  <v2> )" , 'zhcn':"（ <v1> 乘以 <v2> ）", 'zhtc':"（ <v1> 乘以 <v2> ）"  }},
'INPLACE_OR': {'code' : 79, 'gython': "( <v1> | <v2> )", 'var_len':2, 'operator' : "|",
'desc': { 'eng': "(Either  <v1>  or  <v2>  happens)" , 'zhcn':"（ <v1> 或 <v2> 其中之一发生）", 'zhtc':"（ <v1> 或 <v2> 其中之一發生）"  }},
'INPLACE_POWER': {'code' : 67, 'gython': "( <v1> ** <v2> )", 'var_len':2, 'operator' : "**",
'desc': { 'eng': "( <v1>  powered by  <v2> )" , 'zhcn':"（ <v1> 的 <v2> 次乘方）", 'zhtc':"（ <v1> 的 <v2> 次乘方）"  }},
'INPLACE_RSHIFT': {'code' : 76, 'gython': "( <v1> >> <v2> )", 'var_len':2, 'operator' : ">>",
'desc': { 'eng': "( <v1>  right shifted  <v2> )" , 'zhcn':"( <v1> 右移 <v2> )", 'zhtc':"( <v1> 右移 <v2> )"  }}, 
'INPLACE_SUBTRACT': {'code' : 56, 'gython': "( <v1> - <v2> )", 'var_len':2, 'operator' : "-",
'desc': { 'eng': "( <v1>  minus  <v2> )" , 'zhcn':"（ <v1> 减 <v2> ）", 'zhtc':"（ <v1> 减 <v2> ）"  }},
'INPLACE_TRUE_DIVIDE': {'code' : 29, 'gython': "( <v1> / <v2> )", 'var_len':2, 'operator' : "/",
'desc': { 'eng': "( <v1>  divided by  <v2> )" , 'zhcn':"( <v1>  除以  <v2> )", 'zhtc':"( <v1>  除以  <v2> )"  }},
'INPLACE_XOR': {'code' : 78, 'gython': "( <v1>^<v2> )", 'var_len':2, 'operator' : "^",
'desc': { 'eng': "( <v1>^<v2> )" , 'zhcn':"( <v1> ^ <v2> )", 'zhtc':"( <v1> ^ <v2> )"  }},
'JUMP_ABSOLUTE': {'code' : 113, 'gython': "else", 'var_len':0,
'desc': { 'eng': "Else" , 'zhcn':"如以上条件不符, 则用以下:", 'zhtc':"如以上条件不符, 则用以下:"  }},
'JUMP_FORWARD': {'code' : 110, 'gython': "else", 'var_len':0,
'desc': { 'eng': "Else" , 'zhcn':"如以上条件不符, 则用以下:", 'zhtc':"如以上条件不符, 则用以下:"  }},
'JUMP_IF_FALSE_OR_POP': {'code' : 111, 'gython': "elif ( <v1> ):", 'var_len':0,
'desc': { 'eng': "Else if (  <v1>  ) happens:" , 'zhcn':"如以上条件不符, 若 (  <v1>  ) 發生:", 'zhtc':"如以上条件不符, 若 (  <v1>  ) 发生:" } },
'JUMP_IF_TRUE_OR_POP': {'code' : 112, 'gython': "elif not ( <v1> )", 'var_len':0,
'desc': { 'eng': "Else if (  <v1>  ) not happens:" , 'zhcn':"如以上条件不符, 若 (  <v1>  ) 不發生:", 'zhtc':"如以上条件不符, 若 (  <v1>  ) 不发生:" } },
'LIST_APPEND': {'code' : 145, 'gython': " <v1> .append( <v2> )", 'var_len':2,
'desc': { 'eng': " <v1>  appends  <v2> " , 'zhcn':" <v1> 中加入 <v2> ", 'zhtc':" <v1> 中加入 <v2> "  }},
'LOAD_ATTR': {'code' : 106, 'gython': "getattr( <v1> ,  <v2> )" , 'var_len':2,
'desc': { 'eng': "getattr( <v1> ,  <v2> )" , 'zhcn':"getattr( <v1> ,  <v2> )", 'zhtc':"getattr( <v1> ,  <v2> )"  }},
'LOAD_BUILD_CLASS': {'code' : 71, 'gython': " <v1> .<v2> ()", 'var_len':2,
'desc': { 'eng': " <v1>.<v2> ()" , 'zhcn':" <v1> . <v2> ()", 'zhtc':" <v1> . <v2> ()"  }},
'LOAD_CLASSDEREF': {'code' : 148, 'gython': " <v1> . <v2> ()", 'var_len':2,
'desc': { 'eng': " <v1>.<v2> ()" , 'zhcn':" <v1> . <v2> ()", 'zhtc':" <v1> . <v2> ()"  }},
'LOAD_CLOSURE': {'code' : 135, 'gython':"co_freevars[i - len(co_cellvars)]", 'var_len':2,
'desc': { 'eng': "co_freevars[i - len(co_cellvars)]" , 'zhcn':"co_freevars[i - len(co_cellvars)]", 'zhtc':"co_freevars[i - len(co_cellvars)]"  }},
'LOAD_CONST': {'code' : 100, 'gython': " <v1> ", 'var_len':1,
'desc': { 'eng': "Load  <v1> " , 'zhcn':"读取  <v1> ", 'zhtc':"讀取  <v1> "  }},
'LOAD_DEREF': {'code' : 136, 'gython': "i", 'var_len':0,
'desc': { 'eng': "Load i" , 'zhcn':"读取 i", 'zhtc':"讀取 i"  }},
'LOAD_FAST': {'code' : 124, 'gython': " <v1> ", 'var_len':1,
'desc': { 'eng': "Load  <v1> " , 'zhcn':"读取  <v1> ", 'zhtc':"讀取  <v1> "  }},
'LOAD_GLOBAL': {'code' : 116, 'gython': " <v1> ", 'var_len':1,
'desc': { 'eng': "Load  <v1> " , 'zhcn':"读取  <v1> ", 'zhtc':"讀取  <v1> "  }},
'LOAD_NAME': {'code' : 101, 'gython':" <v1> ", 'var_len':1,
'desc': { 'eng': "Load  <v1> " , 'zhcn':"读取  <v1> ", 'zhtc':"讀取  <v1> "  }},
'MAKE_FUNCTION': {'code' : 132, 'gython':"make_function", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'MAP_ADD': {'code' : 147, 'gython':" <v1> .setitem( <v2> [-i],  <v1> ,  <v2> )", 'var_len':2,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'NOP': {'code' : 9, 'gython':"" , 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'POP_BLOCK': {'code' : 87, 'gython':null, 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'POP_EXCEPT': {'code' : 89, 'gython':"except", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'POP_JUMP_IF_FALSE': {'code' : 114, 'gython':"if ( <v1> ):", 'var_len':0,
'desc': { 'eng': "If ( <v1> ) happens:" , 'zhcn':"如果 ( <v1> ) 发生:", 'zhtc':"如果 ( <v1> ) 發生:"  }},
'POP_JUMP_IF_TRUE': {'code' : 115, 'gython':"if (not  <v1> ):", 'var_len':0,
'desc': { 'eng': "If ( <v1> ) not happens:" , 'zhcn':"如果 ( <v1> ) 沒有发生:", 'zhtc':"如果 ( <v1> ) 没有發生:"  }},
'POP_TOP': {'code' : 1, 'gython':'error', 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'PRINT_EXPR': {'code' : 70, 'gython':"error", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'RAISE_VARARGS': {'code' : 13, 'gython':"warning", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'RETURN_VALUE': {'code' : 83, 'gython':null, 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'ROT_THREE': {'code' : 3, 'gython':"pop  <v1> ", 'var_len':0,
'desc': { 'eng': "pop  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'ROT_TWO': {'code' : 2, 'gython':"pop  <v1> ", 'var_len':0,
'desc': { 'eng': "pop  <v1> " , 'zhcn':"移除  <v1> ", 'zhtc':"移除  <v1> "  }},
'SETUP_ANNOTATIONS': {'code' : 85, 'gython':"( <v1>  in locals())", 'var_len':1,
'desc': { 'eng': "( <v1>  in locals())" , 'zhcn':"( <v1>  in locals())", 'zhtc':"( <v1>  in locals())"  }},
'SETUP_ASYNC_WITH': {'code' : 154, 'gython':"frame", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'SETUP_EXCEPT': {'code' : 121, 'gython':"try", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'SETUP_FINALLY': {'code' : 122, 'gython':"end", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'SETUP_LOOP': {'code' : 120, 'gython':"loop", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'SETUP_WITH': {'code' : 143, 'gython':"enter", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'SET_ADD': {'code' : 146, 'gython':"set.add( <v1> [-i], v1 )", 'var_len':1,
'desc': { 'eng': "set.add( <v1> [-i], v1 )" , 'zhcn':"set.add( <v1> [-i], v1 )", 'zhtc':"set.add( <v1> [-i], v1 )"  }},
'STORE_ANNOTATION': {'code' : 127, 'gython':" <v1>  <v2> ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is equals ( <v2> )" , 'zhcn':"( <v1> ) 等于 ( <v2> )", 'zhtc':"( <v1> ) 等於 ( <v2> )"  }},
'STORE_ATTR': {'code' : 95, 'gython':"<v1>.<v2> <v3>", 'var_len':3,
'desc': { 'eng': "( <v1> . <v2> ) is equals (<v3>)" , 'zhcn':"( <v1> . <v2> ) 等于 (<v3>)", 'zhtc':"( <v1> . <v2> ) 等於 (<v3>)"  }},
'STORE_DEREF': {'code' : 137, 'gython':"<v1>[ <v2> ]<v3>", 'var_len':3,
'desc': { 'eng': "( <v1>  with  <v2> -th data) is equals (<v3>)" , 'zhcn':"( <v1> 中第 <v2> 数据) 等于 (<v3>)", 'zhtc':"( <v1> 中第 <v2> 數據) 等於 (<v3>)"  }},
'STORE_FAST': {'code' : 125, 'gython':"<v1>  <v2> ", 'var_len':2,
'desc': { 'eng': " <v1>  <v2> " , 'zhcn':"<v1>  <v2> ", 'zhtc':" <v1>  <v2> "  }},
'STORE_GLOBAL': {'code' : 97, 'gython':"<v1>  <v2> ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is equals ( <v2> )" , 'zhcn':"( <v1> ) 等于 ( <v2> )", 'zhtc':"( <v1> ) 等於 ( <v2> )"  }},
'STORE_NAME': {'code' : 90, 'gython':"<v1>  <v2> ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is equals ( <v2> )" , 'zhcn':"( <v1> ) 等于 ( <v2> )", 'zhtc':"( <v1> ) 等於 ( <v2> )"  }},
'STORE_SUBSCR': {'code' : 60, 'gython':" <v1>[ <v2> ]<v3>", 'var_len':3,
'desc': { 'eng': "( <v1>  with  <v2> -th data) is equals (<v3>)" , 'zhcn':"( <v1> 中第 <v2> 数据) 等于 (<v3>)", 'zhtc':"( <v1> 中第 <v2> 數據) 等於 (<v3>)"  }},
'UNARY_INVERT': {'code' : 15, 'gython':" <v1> ~ <v2> ", 'var_len':2,
'desc': { 'eng': " <v1> ~ <v2> " , 'zhcn':" <v1> ~ <v2> ", 'zhtc':" <v1> ~ <v2> "  }},
'UNARY_NEGATIVE': {'code' : 11, 'gython':" <v1> - <v2> ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is equals -( <v2> )" , 'zhcn':"( <v1> ) 等于 -( <v2> )", 'zhtc':"( <v1> ) 等於 -( <v2> )"  }},
'UNARY_NOT': {'code' : 12, 'gython':" <v1>  not  <v2>  ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is not equals ( <v2> )" , 'zhcn':"( <v1> ) 不等于 ( <v2> )", 'zhtc':"( <v1> ) 不等於 ( <v2> )"  }},
'UNARY_POSITIVE': {'code' : 10, 'gython':" <v1> + <v2> ", 'var_len':2,
'desc': { 'eng': "( <v1> ) is equals ( <v2> )" , 'zhcn':"( <v1> ) 等于 ( <v2> )", 'zhtc':"( <v1> ) 等於 ( <v2> )"  }},
'UNPACK_EX': {'code' : 94, 'gython':"unpack_ex", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'UNPACK_SEQUENCE': {'code' : 92, 'gython':"unpack_seq", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'WITH_CLEANUP_FINISH': {'code' : 82, 'gython':"exit", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'WITH_CLEANUP_START': {'code' : 81, 'gython':"exit", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'YIELD_FROM': {'code' : 72, 'gython':"iter( <v1> )", 'var_len':1,
'desc': { 'eng': "iter( <v1> )" , 'zhcn':"iter( <v1> )", 'zhtc':"iter( <v1> )"  }},
'YIELD_VALUE': {'code' : 86,'gython':"yield", 'var_len':0,
'desc': { 'eng': "" , 'zhcn':"", 'zhtc':""  }},
'G_ELSE': {'code' : 113, 'gython': "else:", 'var_len':0,
'desc': { 'eng': "Else" , 'zhcn':"如以上条件不符, 则:", 'zhtc':"如以上條件不符, 則:"  }},
'G_ELIF': {'code' : 111, 'gython': "elif ( <v1> ):", 'var_len':0,
'desc': { 'eng': "Else if (  <v1>  ) happens:" , 'zhcn':"如以上条件不符, 若 (  <v1>  ) 發生則:", 'zhtc':"如以上条件不符, 若 (  <v1>  ) 发生则:" } },
'G_ELIFN': {'code' : 112, 'gython': "elif not ( <v1> )", 'var_len':0,
'desc': { 'eng': "Else if (  <v1>  ) not happens:" , 'zhcn':"如以上条件不符, 若 (  <v1>  ) 不發生則:", 'zhtc':"如以上条件不符, 若 (  <v1>  ) 不发生则:" } },

}

}

export default Opcode



/*
import numpy as np
a = np.random.rand(5)
price=close
Condition_long=(price>0)
*/