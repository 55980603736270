import React, { Component } from 'react';
import './App.scss';
import gcolor from './public/color.js';

import { createRoot } from 'react-dom/client';

import service from './public/service'
import GService from './Garlican/GarlicanLibrary/gservice';
import Api from './Garlican/GarlicanLibrary/apis'
//import gcolor from './public/color.js';
import Logon from './Components/Logon/index.js';
import Loading from './Components/Loading/index.js';
import Header from './Components/Header/index.js';
import Editor from "./Components/Editor/"

const MAX_ATTP = 5

class App extends Component {
  	
	constructor(props) {
		super(props);
		this.state = {
			isLogin : false,
			languages : [],
			i18n : {},
			regions : [],
			region : "usa",
			language : "zhtc",
			sym_names : {},
			user : null,
			thememode : "light",
			colors : gcolor
			//thememode : "dark"
		}

		this.editor = React.createRef()
		
		this.createLangaugePack = this.createLangaugePack.bind(this)
		this.init = this.init.bind(this)
		this.getColors = this.getColors.bind(this)
		this.logon = this.logon.bind(this)
		this.logout=this.logout.bind(this)
		this.updatevalue = this.updatevalue.bind(this)
		this.changeThemeMode = this.changeThemeMode.bind(this)
	}

	componentDidMount() {
    	this.init()
	}

	componentWillUnmount() {
	}
	 
	async init() {
		const {thememode} = this.state
		var _this = this
		let tokens = await service.getData("token")
		
		await this.getColors( thememode )

		try {
			tokens = JSON.parse(tokens)
			if( tokens ) {
				if( "refresh" in tokens ) {
					let res = await service.refreshtoken( tokens['refresh'] )
					let user = await service.getData("user")
					user = JSON.parse( user )
					if( "access" in res) {
						service["token"] = res.access
						this.setState({
							user : user,
							isLogin : true,
						})
					}
				}
			}
		} catch(e) {
		}
		setTimeout(
				() => {
					if( window.config ) {
						var regions = window.config.region
						var storage = ('localStorage' in  Window)?Window.localStorage:localStorage 
						var obj = {}, isLogin = false
						const _load = (ct , date) => {
							var region = regions[ct].region
							Api.getFilterConfig( "name" ,  region,  date  ).then(
								(names) => {
									var sym_names = names['names']
									for (var i in sym_names) {
										sym_names[i].region = region
										obj[ i ] = ( sym_names[i] )
									}
									ct += 1
									if(  ct === (regions.length)   ) {
										_this.setState({ sym_names : obj })
										var s = { names : obj, date : names.date  } 
										storage.setItem(  "garlican@symbols",  JSON.stringify(  s  )  )
										_this.createLangaugePack( {},  0)
									} else {
										_load( ct, date )
									}

								}
							)
						}
						try {	
							var sym_name = storage.getItem("garlican@symbols")
							sym_name  = JSON.parse( sym_name )
							obj = sym_name.names
							if( sym_name.date ) {
								_load( 0 , sym_name.date )
							}  else {
								_load(0)
							}
							
						} catch(e) {
							storage.setItem("garlican@symbols" , "")
							obj = {}
							_load( 0 )
						}
					}
				}, 300
		)
	}

	createLangaugePack( g,  attempt) {
			if(  Object.keys( g ).length !== window.config.languages.length ) {
				g = {}
				if( attempt < MAX_ATTP ) {
					var _this = this
					setTimeout( (e)=>{
						if ("user_api" in window.config) {
							service.url = (window.config?( window.config.api?window.config.api.user:null ):null)
						} 
						for (var l in window.config.languages) {
							if( window.config.languages[l].key in window )
								g[ window.config.languages[l].key ] = window[ window.config.languages[l].key ]
						}
						if(  Object.keys( g ).length > 0 ) {
							for (var m in window.config.languages ) {
								//var txt = ""
								for (var f in window.func) {
									var key = Object.keys( window.func[f].desc )
									var o_obj = window.func[f].desc[  key[0]  ]
									if(  !( window.config.languages[m].key in window.func[f].desc)  ) {
										window.func[f].desc[  window.config.languages[m].key] = o_obj
									}
								}
								var typ = ['long_strategy', 'short_strategy', 'full_strategy']
								for( var t in typ) {
									for (f in window.templates[typ[t]] ) {	
										key = Object.keys( window.templates[typ[t]][f].description )
										var o_obj = window.templates[  typ[t]  ][f].description[  key[0]  ]
										if(  !( window.config.languages[m].key in window.templates[typ[t]][f].description)  ) {
											window.templates[typ[t]][f].description[  window.config.languages[m].key] = o_obj
										}
									}
								}

							}
							
						
							GService.gi18n = g
							//var language = service.checkSystemLanguage()
							service.url = (window.config?( window.config.api?window.config.api.user:null ):null)

							_this.setState({
								languages : window.config.languages,
								i18n : g,
								regions : ("region" in window.config)?window.config.region:[],
								region : "usa",
							})
							
						}else {
							_this.createLangaugePack( g, attempt )
						}
					} , 500 )
				} else {
					return 
				}
			} else {
				return 
			}
	}

	async logon(obj) {
		let data = await service.login( obj )
		if( "access" in data) {
			service['token'] = data['access']
			let user = await service.getUserProfile()
			
			service.storeData( "user" , JSON.stringify( user ) )
			service.storeData( "token" , JSON.stringify( data ) )
			this.setState({
				user : user,
				isLogin : true
			})
		} else {
		alert("Username / Password is not correct.")
		}
		
	}
	
	logout() {
		service['token'] = null
		service.storeData( "user" , JSON.stringify( {} ) )
		service.storeData( "token" , JSON.stringify( "" ) )
		this.setState({
			user : null,
			isLogin : false
		})
		alert("Logged out.")
	}
	
	updatevalue(obj, cb) {
		this.setState( obj, cb )
	}

	changeThemeMode(mode){
		this.setState( {
			thememode : mode
		}, ()=>{
			this.getColors( mode )
		} )
	}

	async getColors(mode) {
		//console.log(mode)
		var {colors} = this.state 
		function addStyle(styleString) {
            var meta = window.config.meta?window.config.meta:"garlican"
            const style = document.createElement("style");
            style.textContent = styleString;
            document.head.append(style);
        }
        
        function hexToRgbA(hex){
            var c;
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                c= hex.substring(1).split('');
                if(c.length== 3){
                    c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c= '0x'+c.join('');
                return [(c>>16)&255, (c>>8)&255, c&255].join(',');
            }
            throw new Error('Bad Hex');
        }

        if( window.colors ) {
            for( var i in window.colors[mode]) {
                colors[ i ] = window.colors[mode][i]
            }
        }     
        addStyle(`
            :root {
            --gn-bgcolor: ${hexToRgbA(colors.gnBgColor)};
            --gn-black-color: ${hexToRgbA(colors.gnBlackColor)};
            --gn-dark-color: ${hexToRgbA(colors.gnDarkColor)};
            --gn-grey-color: ${hexToRgbA(colors.gnGreyColor)};
            --gn-white-color: ${hexToRgbA(colors.gnWhiteColor)};
            --gn-light-color: ${hexToRgbA(colors.gnLightColor)};
            --gn-green-color : ${hexToRgbA(colors.gnGreenColor)};
            --gn-red-color: ${hexToRgbA(colors.gnRedColor)};
            --gn-darker-color: ${hexToRgbA(colors.gnDarkerColor)};
            --gn-frame-color: ${hexToRgbA(colors.gnFrameColor)};
            --gn-frame-color-2: ${hexToRgbA(colors.gnFrameColor2)};
            --gn-frame-color-3: ${hexToRgbA(colors.gnFrameColor3)};
            --gn-frame-color-4: ${hexToRgbA(colors.gnFrameColor4)};
            --gn-accent-color: ${hexToRgbA(colors.gnAccentColor)};
            --gn-accent-color-2 : ${hexToRgbA(colors.gnAccentColor2)};
            --gn-accent-color-3: ${hexToRgbA(colors.gnAccentColor3)};
            --gn-badge-color: ${hexToRgbA(colors.gnBadgeColor)};
            --gn-white-color: ${hexToRgbA(colors.gnWhiteColor)};
			--gn-text-color: ${hexToRgbA(colors.gnTextColor)};
			--gn-text-color-contrast: ${hexToRgbA(colors.gnTextColorContrast)};
            }
        `);
		//this.forceUpdate()
		this.setState({colors : colors})
	}


	render() {
		const {isLogin, i18n, language, user, languages, sym_names, thememode, colors} = this.state

		if( Object.keys(i18n).length === 0 ) {
			return <Loading thememode={thememode}/>
		}

		if( isLogin ) {
			return (
				<div className={["App", "contrast"].join(" ")}>
					<Header 
						colors={colors}
						thememode={thememode}
						changeThemeMode={this.changeThemeMode}
						user={user} 
						contrast={true} 
						languages={languages} language={language} updatevalue={this.updatevalue} logout={this.logout}/>
					<Editor 
						colors={colors}
						thememode={thememode}
						sym_names={sym_names}
						user={user}
						automated={false}
						isCode={ true }
						i18n={ i18n }
						language={ language }
						updatevalue={ this.updatevalue } 
						ref={this.editor}/>
				</div>
			)
		} else {
			return (
				<>
				<Header 
					colors={colors}
					thememode={thememode}
					contrast={true} 
					changeThemeMode={this.changeThemeMode}
					user={null} languages={languages} language={language} updatevalue={this.updatevalue}/>
				<Logon 
					thememode={thememode}
					logon={this.logon}
					i18n={i18n} 
					language={language} />
				</>
			)
		}
	}

}

export default App;

