import React, { Component } from 'react';
import './index.scss';

import { FaSignOutAlt } from 'react-icons/fa';

import Switch from "react-switch";
//import colors from '../../public/color';


class Header extends Component {
  	
  	constructor(props) {
  		super(props);
      this.state = {

      }
      this.change = this.change.bind(this)
  	}

	componentDidMount() {
	}

	componentWillUnmount() {
	}

  change(k) {
    const { updatevalue } = this.props
    updatevalue({ language : k})
  }
	 
  render() {
    const { languages, language, contrast, logout, thememode, updatevalue, changeThemeMode, colors } = this.props
    return (
      <div className={["Header", contrast?"Contrast":""].join(" ")}>
        {
          logout &&
          <div className={["Left"].join(" ")} onClick={ ()=>logout() }>
            <FaSignOutAlt className={["Fa"].join(" ")} />
          </div>
        }

        <div className={["Theme-Mode"].join(" ")}>
            <span>{thememode?.toUpperCase()}</span>
            <Switch
              onColor={colors.gnAccentColor}
              onHandleColor={colors.gnFrameColor}
              offColor={colors.gnFrameColor}
              offHandleColor={colors.gnLightColor}
              handleDiameter={20}
              height={15}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={ (e)=>{
                changeThemeMode(e?"dark":"light")
              } }
              
              checked={thememode==="dark"}
              className="react-switch"/>
        </div>

        <div className={["Languages"].join(" ")}>
          {
            languages.map( (v,k) => {
              if( v['key'] !== language ) {
                return <div className={["Options"].join(" ")} key={k} onClick={(e)=>this.change(v['key'])}> { v['name'] } </div>
              } else {
                return null
              }
            } )
          }
        </div>
      </div>
    )
  }

}

export default Header;

