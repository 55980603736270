import React, { Component } from 'react';
import './index.scss';
import service from '../../public/service'
import Lottie from 'react-lottie';

class Loading extends Component {
  	
  	constructor(props) {
  		super(props);
      this.state = {
        logo : null
      }
      this.init = this.init.bind(this)
  	}

	componentDidMount() {
    this.init()
	}

	componentWillUnmount() {
	}

  componentDidUpdate(props, state){
    if( props.thememode !== this.props.thememode ) {
      this.init()
    }
  }
	 
  async init() {
    const {thememode} = this.props
    let data = await fetch(window[ `lotties_logo_${thememode}` ] )
    const res = await data.json()
    this.setState({
      logo : res
    })
  }

  render() {
    const {isLogin, cover, thememode} = this.props
    const {logo} = this.state
    return (
      <div className={["Loading", cover?"C":""].join(" ")}>
          {
            !logo &&
            <img className={["L-Logo"].join(" ")} src={window[ `logo_${thememode}` ]  }></img>
          }
          {
            logo &&
            <Lottie 
                className={["L-Logo"].join(" ")}
                options={{
                  loop: true,
                  autoplay: true, 
                  animationData: logo,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={100}
                width={"auto"}/>
          }
          <div className={["Message"].join(" ")}>{!cover?"INITIALIZING ... ...":"I AM THINKING..."}</div>
      </div>
    )
  }

}

export default Loading;

