import React, { Component } from 'react';
import './index.scss';
import service from '../../public/service'
import Lottie from 'react-lottie';

const frame = {
  "logon" : ["username", "password"],
  "register" : ["username","first_name", "last_name","password", "confirm_password"],
}

class Logon extends Component {
  	
  	constructor(props) {
  		super(props);
      this.state = {
        typ : "logon",
        formData : {},
        logo : null,
      }

      this.init = this.init.bind(this)

      this.change = this.change.bind(this)
      this.logon = this.logon.bind(this)
  	}

	componentDidMount() {
    this.init()
	}

	componentWillUnmount() {
	}

  componentDidUpdate(props, state){
    if( props.thememode !== this.props.thememode ) {
      this.init()
    }
  }

  change(key, value) {
    var {formData} = this.state
    formData[key] = value
    this.setState({
      formData : formData      
    })
  }
	 
  logon() {
    const { logon } = this.props
    var {formData, typ} = this.state
    const f = frame[typ]
    var valid = true, obj = {}
    for (var k of f) {
      if( !(k in formData) || formData[k].length===0 ) {
        valid = false
      } else {
        obj[k] = formData[k]
      }
    }
    if( valid ) {
        obj["username"] = obj["username"].toLowerCase()
        logon( obj )
    }


  }

  async init() {
    const {thememode} = this.props
    let data = await fetch(window[`lotties_logofull_${thememode}`] )
    const res = await data.json()
    this.setState({
      logo : res
    })
  }

  render() {
    const {isLogin, i18n, language, thememode} = this.props
    const { typ, formData, logo } = this.state
    window.document.title = i18n[language]['title']


      return (
        <div className={["Logon"].join(" ")}>
          <div className={["L-Frame"].join(" ")}>
              {
                !logo &&
                <img className={["L-Logo"].join(" ")} src={window[`logofull_${thememode}`]}></img>
              }
              {
                logo &&
                <Lottie 
                  className={["L-Logo"].join(" ")}
                  options={{
                    loop: true,
                    autoplay: true, 
                    animationData: logo,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={100}
                  width={"auto"}/>
              }
            {
              frame[typ].map( (v,k) => {
                return (
                  <div className={['L-Holder']} key={k}>
                    <div className={['L-Title']}>{i18n[language][v]}</div>
                    <input 
                      type={ (v.indexOf("password") > -1)?"password":"text" }
                      className={['L-Input'].join(" ")} value={ (v in formData)?formData[v]:"" } onChange={ (e)=>this.change(v, e.target.value) } />
                  </div>
                )
              } )
            }
            <div className={['L-Holder']}>
              <div className={["btn-outline","contrast", "L-Btn"].join(" ")} onClick={this.logon}>{ 
                i18n[language]['login']
              }</div>
            </div>
          </div>
        </div>
      )
  }

}

export default Logon;

