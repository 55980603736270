//import id from "@xmpp/id";
//import { create, json, piDependencies } from "mathjs";
//import { servicesVersion } from "typescript";
//import response from "./response.js"

var service = {
    LMAX : 30,
    url : (window.config?( window.config.api?window.config.api.user:null ):null) ,   
    redirect_uri : window.location.origin+"/create", 
    token : "",
    user : {},
    score_scale : [
       { score :95, rank : "A+" , rate : "Very high"},
       { score : 90, rank  : "A"},
       { score : 85, rank  : "A-" },
       { score : 80, rank  : "B+" },
       { score : 75, rank  : "B"},
       { score : 70, rank  : "B-"},
       { score : 65, rank  : "C+"},
       { score :60, rank  : "C"},
       { score :55, rank  : "C-"},
       { score :50, rank  : "D+", rate : "Middle"},
       { score :45, rank  : "D"},
       { score :40, rank  : "D-"},
       { score :35, rank  : "E+"},
       { score :30, rank  : "E"},
       { score :25, rank  : "E-"},
       { score :20, rank  : "F+"},
       { score :15, rank  : "F"},
       { score :10, rank  : "F-"},
       { score :0, rank  : "F-", rate : "Very Low"},
    ],
    numberScale : [
        { key : "default", value : 1 , display : '' },
        { key : "thousand", value : 1000 , display : 'K'},
        { key : "million" , value : 1000000 , display : 'M'},
        { key : "billion" , value :1000000000 , display : ',B'},
        { key : "trillion" , value :1000000000000 ,display : 'T' },
    ],
    detect : ()=> {
        //var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        //var isFirefox = typeof InstallTrigger !== 'undefined';
        // Safari 3.0+ "[object HTMLElementConstructor]" 
        //var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        // Internet Explorer 6-11
        //var isIE = /*@cc_on!@*/false || !!document.documentMode;
        // Edge 20+
        //var isEdge = !isIE && !!window.StyleMedia;
        // Chrome 1 - 79
        //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // Edge (based on chromium) detection
        //var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
        // Blink engine detection
        //var isBlink = (isChrome || isOpera) && !!window.CSS;
        var userAgent = navigator.userAgent.toLowerCase();
        var isIE = !/opera|opr\/[\d]+/.test(userAgent) && /(msie|trident)/.test(userAgent);
        return isIE
    },

    "clearAllData" : () => {
        window.localStorage.clear()
    },
    
    "storeData" : async (key,value) => {
        try {
            var attr = `@garlican/`
            const jsonValue = JSON.stringify(value);
            await window.localStorage.setItem( (attr+key), jsonValue);
        } catch (e) {
          await window.localStorage.setItem(key, value);
        }
    },
    
    "getData" : async (key, placeholder=[]) => {
        try {
          var attr = `@garlican/`
          const jsonValue = await window.localStorage.getItem( (attr+key)  );
          if ( jsonValue !== null ) {
            return JSON.parse(jsonValue)
          } else {
            return null
          }
        } catch (e) {
          return placeholder
        }
    },

    login : async (obj) =>{
        var url = service.url + "user/token"
        //var body = {  username : username.toLowerCase(), password : password}
        let res = await fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(obj)
            }
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    refreshtoken : async (token) => {
        var url = service.url + "user/token/refresh"
        var body = {  refresh : token }
        let res = await fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }

    },

    register : (username, password, firstname, lastname) => {
        var url = service.url + "user/register"
        var body = {  username : username.toLowerCase(), password : password, first_name : firstname, last_name : lastname, email : username}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )

    },

    logout : () =>{
        service.user = null
        service.token = ""
        service.clearAllData()
    },

    forget : (username)=> {
        var url = service.url + "user/forgetpassword"
        var body = {  username : username.toLowerCase()}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    restore : ( key, username, password ) => {
        var url = service.url + "user/passwordrecovery"
        var body = {  username : username.toLowerCase(), password : password, key : key}
        return fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)
            }
        ).then(
            (response) => response.json()
        )
    },

    validateEmail : (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    getUserProfile : async (id) => {
        var str = id?parseInt(id, 10):"me"
        var url = service.url + "user/" + str
        let res = await fetch(
            url, 
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            }
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    updateUserProfile : async (obj) => {
        var url = service.url + "user/me"
        let res = await fetch(
            url, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            }
        )
        try {
            let result = await res.json()
            return await res
        } catch (e) {
            return { "status" : 500 }
        }
    },

    isOwner : ( user_id ) => {
        if(service.user) {
            return user_id === service.user.user_id
        } else {
            return false
        }
    },

    formatDate(d, hms, date) {
        d = d?d:null
        try {
            d = d.split(" ")
            var d1 = "", d2 = ""
            d[0].toString()
            if( d.length < 2 ) {
                d1 = d[0].split("-")
                d = new Date( d1[0], (parseInt(d1[1])-1).toString(), d1[2] )
            } else {
                d1 = d[0].split("-")
                d2 = d[1].split(":") 

                d = new Date( d1[0], (parseInt(d1[1])-1).toString() , d1[2], d2[0], d2[1], d2[2] )
                
            }
            if(date) {
                return d
            }
            
        }
        catch(e) {
            d = d?new Date( d ):new Date()
        }

        var month = '' + (d.getMonth() + 1  ),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = "",
            minute = "",
            second = ""
        if(hms) {
            hour = '' + d.getHours();
            minute = '' + d.getMinutes();
            second = '' + d.getSeconds();
            if (hour.length < 2) 
                hour = '0' + hour;
            if (minute .length < 2) 
                minute  = '0' + minute ;
            if (second  .length < 2) 
                second  = '0' + second  ;
        }
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        
        
        if( hms) {
            var st1 = [year, month, day].join('-');
            var st2 = [hour, minute, second].join(':');
            return st1 + " " + st2
        } else {
            
            return [year, month, day].join('-');
        }
    },

    checkSystemLanguage() {
        var language = window.navigator.userLanguage || window.navigator.language;
        var _lang = 'eng'
        var keys = []
        if ('map' in window) {
          if(  window['map'].length > 0 ) {
            _lang = window['map'][0].key
          }
          keys = window.map.map( (v,k) => v.key )
        }

        if( language ) {
          if( language.indexOf("-CN") > -1 ) {
            //return "zhcn"
            return ( keys.includes('zhcn')?"zhcn":_lang )
          } else if( language.indexOf("zh") > -1 ) {
            //return "zhtc"
            return ( keys.includes('zhtc')?"zhcn":_lang )
          } else {
            return _lang
          }
        } else {
          return _lang
        }
    },

    getPortfoilioList : async (obj) => {
        obj = ( (obj===undefined) || (obj===null))?{}:obj
        //GET /trading/portfolio?type=<latest/bookmark/>&page=<0>&user_id=<id>&search=<criteria>
        var arr = []
        var keys = [
            'bookmarked','type', 'user_id', 'page', 'access_mode','region',
            'order_by', 'initial_capital_min', 'initial_capital_max', 
            'win_rate_min', 'win_rate_max', 'sharpe_ratio_min', 'sharpe_ratio_max', 
            'profit_rate_min', 'profit_rate_max', 'turnover_rate_min' , 'turnover_rate_max',
            'win_rate_rank_min', 'win_rate_rank_max', 'sharpe_ratio_rank_min', 'sharpe_ratio_rank_max', 
            'profit_rate_rank_min', 'profit_rate_rank_max', 'turnover_rate_rank_min' , 'turnover_rate_rank_max',
            'with_subscription'
        ]
        for (var k in keys) {
            if( keys[k] in obj ) {
                //arr.push( ( k  + '=' + keys[k]) )
                arr.push( ( keys[k]  + '=' + obj[ keys[k] ] ) )
            }
        }
    
        var str = ( arr.length > 0 )?( "?"+arr.join("&") ):""
        var url = service.url + "trading/portfolios" + str
        var header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if( 'token' in service ) {
            if(service.token) {
                header['Authorization'] = ('Bearer ' + service.token)
            }
        }
        let res = await fetch(
            url, 
            {
                method: 'GET',
                headers: header,
            }
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }

    },

    getPortfoilioById : async (id) => {
        var url = service.url + "trading/portfolio/" + id
        var header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if ( service.token !== "" ) {
            header['Authorization'] = 'Bearer ' + service.token
        }
        let res = await fetch(
            url, 
            {
                method: 'GET',
                headers: header,
            }
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    getActivitiesByPortfolioId : (id)=> {     
    },

    createPortfolio : async (obj) => {
        obj['initial_capital'] = parseFloat( obj['initial_capital'] )
        obj['transaction_fee'] = parseFloat( obj['transaction_fee'] )
        var keys = [ 
            'access_mode', 'description', 'initial_capital', 'input', 'name',
            'partition', 'transaction_fee', "region"
        ]
        var _obj = {}
        for (var i in keys) {
            if( keys[i] in obj )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
        var aurl =  service.url + "trading/portfolio"
        let res = await fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },
    
    updatePortfolio : async (pid, obj) => {
        obj['initial_capital'] = parseFloat( obj['initial_capital'] )
        obj['transaction_fee'] = parseFloat( obj['transaction_fee'] )

        var keys = [ 
            'access_mode', 'description', 'initial_capital', 'input', 'name', 'feature',
            'partition', 'profit_rate_rank', 'sharpe_rate_rank' , 'total_portfolios',
            'transaction_fee',  'turnover_rate_rank', 'win_rate_rank' ,'region',
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] !== null )
                _obj[  keys[i]  ] = obj[ keys[i] ]
        }
        obj = _obj
        var aurl =  service.url + "trading/portfolio/" + pid
        let res = await fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }

    },

    deletePortfolio : async (id) => {
        var aurl =  service.url + "trading/portfolio/" + id
        let res = await fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    subscribePortfolioById : (id) => {
        var aurl =  service.url + "trading/portfolio/" + id + "/subscribe"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) => res.json()
        )
    },

    createStrategy: async (obj) =>{
        var aurl =  service.url + "trading/strategy"
        var keys = [ 
            'name', 'description', 'code', 'portfolio_id', 'filters' , 'strategy_template_id'
        ]
        var _obj = {}
        for (var i in keys) {
            if( (keys[i] in obj) && (  obj[ keys[i] ] !== null ) ) {
                _obj[  keys[i]  ] = obj[ keys[i] ]
            }
        }
        obj = _obj
        let res = await fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    updateStrategy: async (sid, obj)=> {
        var aurl =  service.url + "trading/strategy/" + sid
        var keys = [ 
            'name', 'description', 'code', 'filters' , 'strategy_template_id'
        ]
        var _obj = {}
        for (var i in keys) {
            if( obj[ keys[i] ] != null ) {
                _obj[  keys[i]  ] = obj[ keys[i] ]
            }
        }
        obj = _obj

        let res = await fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

    deleteStrategy: (sid) => {
        var aurl =  service.url + "trading/strategy/" + sid
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getStrategyById: ( sid ) => {
        var aurl =  service.url + "trading/strategy/" + sid
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createReport: (pid, obj) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/report"
        var keys = [ 
            'initial_capital', 'report_type', 'trade_list', 'transaction_fee'
        ]
        var key2 = [ 
            "symbol",
            "decision",
            "date",
            "price",
            "group",
            "profit",
            "boardlot",
            "dividends"
        ]
        var _obj = {}
        for (var i in keys) {
            _obj[  keys[i]  ] = obj[ keys[i] ]
            if(  keys[i] === 'trade_list' ) {
                for (var j in obj[ keys[i] ] ) {
                    var _o = {}
                    for (var k in key2) {
                        _o[ key2[k] ] = obj[ keys[i] ][j][  key2[k] ]
                    }
                    _obj[  keys[i]  ][  j  ] = _o
                }
            }
        }
        obj = _obj

        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getReport: (pid, rid, forward) => {
        var str = forward?"":"?include_trade_list=true"
        var aurl =  service.url + "trading/portfolio/"+pid+"/report/"+rid+str
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
                //body : JSON.stringify( obj )
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createBookmark: (pid) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/bookmark"
        return fetch(
            aurl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    removeBookmark: (pid) => {
        var aurl =  service.url + "trading/portfolio/"+pid+"/bookmark"
        return fetch(
            aurl,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        ).then(
            (res) =>  res.json()
        )
    },

    getTemplateById : async (st_id) => {
        var aurl = service.url +"trading/strategy_template/" + st_id
        let res = await fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },


    getTemplates : async (obj) => {
        var keys = [ "user_id", "search", "page", "region" ]
        var str = "", arr = []
        if(obj) {
            for (var k in keys) {
                if( keys[k] in obj ) {
                    arr.push( (keys[k] + "=" + obj[keys[k]]) )
                }
            }
        }
        str = (arr.length>0)?("?"+ arr.join("&") ):""

        var aurl =  service.url +"trading/strategy_templates" + str
        let res = await fetch(
            aurl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + service.token
                },
            },
        )
        try {
            let result = await res.json()
            return await result
        } catch (e) {
            return {}
        }
    },

////NEW
    validateNum : (z) => {
        try {
            return (z.match(/^\d+$/)||z.match(/^[+-]?\d+(\.\d+)?$/))?true:false
        } catch(e) {
            return false
        }

    },

    getNumberFormat : (value) => {
        value = parseFloat( value, 10 )
        var keys = JSON.parse( JSON.stringify( service.numberScale ) )
        keys.reverse()
        var str = value
        for (var i in keys ) {
          if(  (value/keys[i].value) >= 1 ) {
            str = (Math.round(value * 100/keys[i].value)/100) + keys[i].display
            break
          }
        }
        return str
    },

    encode : (str) => {
        return encodeURIComponent(str)
    },

    decode : (str) => {
        return decodeURIComponent(str)
    },

    calcExp : (exp) => {
        //=ROUND((POWER(1.15,A3)-1)*1000/11,0)
        var lv = Math.floor( Math.log(   (exp * 11/1000) + 1  )/Math.log( 1.15   ) )+1
        var _current_exp = Math.floor((Math.pow( 1.15, lv-1 ) - 1)*1000/11 )
        var _next_exp = Math.floor((Math.pow( 1.15, lv ) - 1)*1000/11 )
        return {
            current_exp : _current_exp,
            next_exp : _next_exp,
            lv : lv,
            exp : exp
        }
    },

    checkProps : (p) => {
        var _props = {}
          for (var i in p) {
            if( !( ["refs", "trader_ref"].includes(i))  ){
              _props[ i ] = p[i]
            }
          }
        return _props
    },
    
    getAllPrices : () => {
        //https://xmpp.garlican.com/stripe/checkout/session
        var aurl = service.url +"stripe/prices"
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (service.token) {
            headers['Authorization'] = 'Bearer ' + service.token
        }
        return fetch(
            aurl,
            {
                method: 'GET',
                headers: headers,
            },
        ).then(
            (res) =>  res.json()
        )
    },

    createCheckout : (price_id) => {
        var aurl = service.url +"stripe/checkout/session"
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (service.token) {
            headers['Authorization'] = 'Bearer ' + service.token
        }

        var body = {}
        body.price_id = price_id
        body.cancel_url = window.location.href
        body.success_url = window.location.href

        return fetch(
            aurl,
            {
                method: 'POST',
                headers: headers,
                body : JSON.stringify(body)
            },
            
        ).then(
            (res) =>  res.json()
        )
    },

    updateAutomate : (pid, enable) => {
        var aurl = service.url +"trading/portfolio/"+pid+"/automate"
        //https://xmpp.garlican.com/trading/portfolio/{portfolio_id}/automate
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (service.token) {
            headers['Authorization'] = 'Bearer ' + service.token
        }

        if ( enable === false) {
            aurl += "?enabled=false"
        }

        //var body = {}
        //body.enabled = enable

        return fetch(
            aurl,
            {
                method: 'POST',
                headers: headers,
                //body : JSON.stringify(body)
            },
            
        ).then(
            (res) =>  res.json()
        )
    },

 //Stripe
 //https://xmpp.garlican.com/stripe/prices
    

}


export default service