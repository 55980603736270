import React from "react";
import service from "../../../public/service";
import GService from "../../../Garlican/GarlicanLibrary/gservice";
import './index.scss'
import { FaCaretLeft, FaTimesCircle } from "react-icons/fa"
import { FormControl, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import ai from "../../../public/aiempower";
import Flag from 'react-world-flags'

export default class Panel extends React.Component {

  constructor(props) {
    super(props);  
    this.state = {
      mode : "mine"
    } 
    this.init = this.init.bind(this)
    this.change = this.change.bind(this)
    this.screener = this.screener.bind(this)
    this.changeStrategy = this.changeStrategy.bind(this)
    this.selectStrategy = this.selectStrategy.bind(this)
    this.selectTemplate = this.selectTemplate.bind(this)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
  }

  init() {
    this.setState({
      mode : "mine"
    })
  }

  change(key, value, checkNum) {
    var {selected, updatevalue} = this.props
    if (!checkNum) {
      selected[key] = value
    } else {
      if( value.substring(value.length-1, value.length) === ".")  {
        value = `${value}5`
      }
      if( service.validateNum( value ) || value.length === 0 ) {
        if( value.length !== 0 ) {
          selected[key] = parseFloat(value, 10)
        } else {
          selected[key] = parseFloat(0, 10)
        }
      }
    }

    if( key === "region" ) {
      selected['strategy_list'][0]['filters'] = GService.defaultFilter[ value ]
    }

    updatevalue({
      selected : selected
    })
  }

  changeStrategy(key, value) {
    var {selected, updatevalue} = this.props
    selected['strategy_list'][0][key] = value
    updatevalue({
      selected : selected
    })
  }

  async selectStrategy(v) {
    const { updatevalue } = this.props
    let data = await service.getPortfoilioById(v['id'])
    
    try {
      if( data['strategy_list'][0]['filters'][0]["type"] !== "symbol" ) {
        //data['region'] = "usa"
        //console.log( data['strategy_list'][0]['filters'] )
        //data['strategy_list'][0]['filters'] = GService.defaultFilter[ "usa" ]
        data['region'] = v['region']
        data['strategy_list'][0]['filters'] = await this.screener( data['strategy_list'][0]['filters'], v['region'] )
      }
    } catch(e) {
      data['region'] = "usa"
      data['strategy_list'][0]['filters'] = GService.defaultFilter[ "usa" ]
    }
    updatevalue({
      selected : data
    })
    
  }

  async selectTemplate(v) {
    const { updatevalue } = this.props
    let data = await service.getTemplateById(v['id'])
    var gp = GService.createGroupObject( {} , null , "" )
    data['enable'] = true
    data["id"] = "temp_id"
    gp['strategy_list'] = [ data ]
    gp['region'] = "usa"
    data['filters'] = GService.defaultFilter[ "usa" ]
    gp['partition'] = { "temp_id" : "100" }
    updatevalue({
      selected : gp
    })
    this.setState({mode : "mine"})
  }

  async screener(filters, region) {
    var body = {
      "filters" : filters,
      "region" : region
    }
    let data = await ai.screener( body )
    var syms = data[0]['value'].map( (v) => v["symbol"] )
    return [{key: "symbol", opr: "in", type: "symbol", value: syms.join(",")}]
  }

  render() {
    const {i18n, language, create, botfolio, templates, selected, updatevalue, sym_names, backtest, deleteGroup, favorite_symbol_list, updateFavoriteList} = this.props
    const {mode} = this.state

    if( !selected ) {
      try {
        return (
          <div className={[ 'EPanel'  ].join(' ')} >
            <div className={ ["E-Holder"].join(" ") } >
              <div className={["btn"]} onClick={()=>create()}>{i18n[language]["create"]}</div>
            </div>
            <div className={ ["E-Holder", "Expand"].join(" ") } >
              <div className={ ["Tabs"].join(" ") } >
                <div className={ ["Tab", "btn-outline", (mode==="mine")?"":"Disabled"].join(" ") } onClick={()=>this.setState({mode : "mine"})}>{i18n[language]['mycat'].toUpperCase()}</div>
              </div>
              
              <div className={ ["E-Content"].join(" ") } >
                {
                  mode === "mine" && botfolio.length > 0 &&
                  botfolio.map(
                    (v,k) => {
                      const code = v['region']
                      return (
                        <div 
                          onClick={ ()=>this.selectStrategy(v) }
                          key={k} className={['E-List'].join(" ")}> 
                          <Flag code={ code } height={10}/>
                          <span>{ v['name']}</span>
                      </div>
                      )
                    }
                  ) 
                }
                {
                  mode === "template" && templates.length > 0 &&
                  templates.map(
                    (v,k) => {
                      return (
                        <div 
                          onClick={ ()=>this.selectTemplate(v) }
                          key={k} className={['E-List', "T"].join(" ")}> { v['name']} </div>
                      )
                    }
                  ) 
                }
                {
                  (mode === "mine" && botfolio.length === 0) || ( mode === "template" && templates===0) &&
                  <div className={["E-Empty"].join(" ")}>{"No Strategy Found"}</div>
                }
              </div>

            </div>
          </div>
        );
      } catch(e) {
        return null
      }
    } else {
      try {
        const {strategy_list} = selected
        const {filters} = strategy_list[0]
        const symbols = (filters[0]['value']!=="")?filters[0]['value'].split(","):[]
        var options = []
        for (var s in sym_names) {
          if( sym_names[s]['region'] === selected['region'] ) {
            options.push( s )
          }
        }
        return (
          <div className={[ 'EPanel'  ].join(' ')} >
            <div className={ ["E-Row-Holder"].join(" ") } >
              <FaCaretLeft className={["E-Icon"].join(" ")} onClick={()=>updatevalue({selected:null, mode : "simple"})}/>
              <input className={["E-Name", "contrast"].join(" ")} 
                onChange={(e)=>this.change( "name", e.target.value )}
                value={ selected['name'] } />
              <FaTimesCircle className={["E-Remove"].join(" ")} onClick={()=>deleteGroup(selected)}/>
            </div>

            <div className={ ["E-Grow-Holder"].join(" ") } >
              <div className={ ["E-Row-Holder", "Expand"].join(" ") }>  
                <div onClick={(e)=>this.change("region", "usa") }
                  className={["btn-outline", selected['region']==="usa"?"Selected":"Disabled"].join(" ")} > {"USA"} </div>
                <div onClick={(e)=>this.change("region", "hkg") } 
                  className={["btn-outline", selected['region']==="hkg"?"Selected":"Disabled"].join(" ")}> {"HKG"} </div>
              </div>
              <div className={["E-Grow-Content"].join(" ")}>
                <div className={["E-Title"].join(" ")}> { `${i18n[language]["symbol"]} (${symbols?.length})` } </div>
                <Typeahead
                  id={"symbol"}
                  className={ ['Symbols' ].join(" ") }
                  labelKey={ "name" }
                  multiple={  true }
                  paginate={ true }
                  clearButton={ false }
                  onChange={(e)=>{ 
                    var f = [{key: "symbol", opr: "in", type: "symbol", value: e.join(",")}]
                    this.changeStrategy("filters", f )
                  } }
                  selected={  symbols  }
                  options={  options }
                  placeholder={ "Please pick symbols" }
                />
                <div className={['E-Save'].join(" ")}>
                  {
                    favorite_symbol_list[ selected['region'] ] &&
                    <div
                      onClick={()=>{
                        var f = [{key: "symbol", opr: "in", type: "symbol", value: favorite_symbol_list[ selected['region'] ].join(",")  }]
                        this.changeStrategy("filters", f )
                      }} 
                      className={['btn'].join(" ")} >{"Use Favorite"}</div>
                  }
                  <div 
                    onClick={ ()=>{
                      updateFavoriteList( selected['region'], symbols )
                    } }
                    className={['btn', "Save"].join(" ")} >{"Save As Favorite"}</div>
                </div>
              </div>
              
              <div className={ ["E-Row-Holder", "Expand", "PaddingTop"].join(" ") }>  
                <div className={["E-Input-Holder"].join(" ")}>
                  <div className={["E-Input-Title"].join(" ")}> {i18n[language]["initial"]} </div>
                  <input className={["contrast"].join(" ")} value={selected["initial_capital"]} onChange={(e)=>{
                    this.change( "initial_capital", e.target.value, true )
                  }} />
                </div>
                <div className={["E-Input-Holder"].join(" ")}>
                  <div className={["E-Input-Title"].join(" ")}> {i18n[language]["transaction_fee"]} </div>
                  <input className={["contrast"].join(" ")} value={selected["transaction_fee"]} onChange={(e)=>{
                    this.change( "transaction_fee", e.target.value, true )
                  }}/>
                </div>
              </div>

            </div>
            <div className={ ["E-Holder"].join(" ") } >
              <div className={["btn"]} onClick={()=>backtest()}>{i18n[language]['backtesting_btn'].toUpperCase()}</div>
            </div>
          </div>
        )
      } catch(e) {
        console.log(e)
        return null
      }
    }
  }
}


