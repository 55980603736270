import React from "react";
import * as XLSX from "xlsx";
import './index.scss'

import service from '../../public/service'
import apis from '../GarlicanLibrary/apis'
//import colors from '../../public/color.js';

import {FaTimes, FaCheck, FaChevronRight, FaChevronLeft} from "react-icons/fa"

import { Modal, OverlayTrigger, Tooltip, Table, Button, ModalDialog, Spinner, FormControl, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { VictoryLine, VictoryChart, VictoryAxis, VictoryZoomContainer , createContainer, VictoryScatter,VictoryTooltip, VictoryCandlestick, VictoryStack, VictoryBar } from 'victory';


const BRENCHMARK = {
  usa : [
    { key : "^GSPC", name : "S&P500"},
    { key : "XLY" , name : "Consumer Discretionary"},
    { key : "XLC" , name : "Communication Services"},
    { key : "XLK" , name : "Information Technology"},
    { key : "XLI" , name : "Industrials"},
    { key : "XLB" , name : "Materials"},
    { key : "XLE" , name : "Energy"},
    { key : "XLP" , name : "Consumer Staples"},
    { key : "XLV" , name : "Health Care"},
    { key : "XLU" , name : "Utilities"},
    { key : "XLF" , name : "Financials"},
    { key : "XLRE", name : "Real Estate"}
  ],
  hkg : [
    { key : "^HSI" ,name :  "Hang Seng Index"}
  ]
}


const VictoryCustomContainer = createContainer("zoom", "voronoi");
const fix = 10
const maths = require('mathjs');

const TRADE_DAYS = 360

const tabs = [
  { key : 'overview', value : 'r_overview' },
  { key : 'log', value : 'r_tradelog' },
]

const contents = [
  { key : 'h_basic' , value : [  
      {  key : 0 , type : 'docker' , value : [  
        {  key : 'initial' , value : 'initial_capital', type : 'input'},
        {  key : 'transaction_fee' , value : 'transaction_fee', type : 'input'},
      ]},
      {  key : 1 , type : 'docker' , value : [  
        {  key : 'profit' , value : 'profit'}
      ]},
      {  key : 2 , type : 'docker' , value : [  
        {  key : 'profit_from_trades' , value : 'profit_from_trades'},
        {  key : 'dividends' , value : 'dividends'}
      ]},
      {  key : 3 , type : 'docker' , value : [  
        {  key : 'period_days' , value : 'period_days'}
      ]},
      {  key : 4 , type : 'docker' , value : [  
        {  key : 'trade_count' , value : 'trade_count'},
        {  key : 'symbol_count' , value : 'symbol_count'},
      ]},
      {  key : 5 , type : 'docker' , value : [  
        {  key : 'buyhand' , value : 'buyhand'},
        {  key : 'sellhand' , value : 'sellhand'},
      ]},
      {  key : 6 , type : 'docker' , value : [  
        {  key : 'strategy_count' , value : 'strategy_count'},
        {  key : 'total_trading_days' , value : 'total_trading_days'},
      ]},
    ] 
  },

  { key : 'h_profit' , value : [ 
      {  key : 0 , type : 'docker' , value : [  
        {  key : 'avg_profit' , value : 'avg_profit'}
      ]},
      {  key : 1 , type : 'docker' , value : [  
        {  key : 'max_profit' , value : 'max_profit'},
        {  key : 'min_profit' , value : 'min_profit'},
      ]},
      {  key : 2 , type : 'docker' , value : [  
        {  key : 'annual_return' , value : 'annual_return'},
        {  key : 'true_return_from_botfolio' , value : 'true_return_from_botfolio'},
      ]}
    ] 
  },

  { key : 'h_risk' , value : [  
      {  key : 0 , type : 'docker' , value : [  
        {  key : 'volatility' , value : 'volatility'},
        {  key : 'sharpe_ratio' , value : 'sharpe_ratio' },
      ]},
      {  key : 1 , type : 'docker' , value : [  
        {  key : 'average_onmarket' , value : 'average_onmarket'},
        {  key : 'average_reserved_rate' , value : 'average_reserved_rate'},
      ]},
      {  key : 2 , type : 'docker' , value : [  
        {  key : 'beta' , value : 'beta'},
      ]},
    ] 
  },

  { key : 'h_win_rate' , value : [  
      {  key : 0 , type : 'docker' , value : [  
        {  key : 'win_rate' , value : 'win_rate'},
      ]},
      {  key : 1 , type : 'docker' , value : [  
        {  key : 'profit_from_wins' , value : 'profit_from_wins'},
        {  key : 'deficit_from_losses' , value : 'deficit_from_losses'},
      ]},
    ] 
  },

  { key : 'h_turnover_rate' , value : [  
    {  key : 0 , type : 'docker' , value : [  
      {  key : 'turnover_rate' , value : 'turnover_rate'},
    ]},
    {  key : 1 , type : 'docker' , value : [  
      {  key : 'average_trade_time' , value : 'average_trade_time'},
    ]},
  ] 
},



]



export default class GarlicanReport extends React.Component {

  constructor(props) {
    super(props);  
    this.state = {
      domain : [-1,1],
      w : 0,
      h : 0,
      data : [],
      r_data : [],
      symbolp : [],
      symbolpw : [],
      symbolpl : [],
      strategy : [],
      srange : {x1:0,x0:0,l:0, dxr:0, dxl:0},
      strange : {y1:0,y0:0,l:0, dyu:0, dyd:0, max : 0, min : 0},
      showDate : null,
      snames : {},
      selected : {key:null, value:null},
      showbacktest_report : true,
      showHOverbacktest_report : false,
      backtest_report : {},
      transaction_fee : 0,
      initial_capital : 0,
      bm : [],
      dates : [],
      branchmark : BRENCHMARK['usa'][0],
      bm_list : BRENCHMARK['usa'],
      showConfirm : false,
      body : null,
      hasReport : false,
      isOwner : false,
      report : [],
      symData : [],
      points : [],
      selectedTab : "overview",
      showChart : true,

      onstock : [],
      cash : [],

      selectLog : 'nested',
      showReport : props.showReport,
    } 
    this.calcpTemp = this.calcpTemp.bind(this)
    this.calcpReport = this.calcpReport.bind(this)
    this.hideAll = this.hideAll.bind(this)
    this.onsubmit = this.onsubmit.bind(this)
    this.saveReport = this.saveReport.bind(this)
    this.exit = this.exit.bind(this)
    this.getReport = this.getReport.bind(this)
    this.selectSym = this.selectSym.bind(this)

    this.corr = this.corr.bind(this)
    this.download = this.download.bind(this)
    this.getBMPrice = this.getBMPrice.bind(this)

    this.update = this.update.bind(this)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
      var _this = this
      if( this.props.showReport !== state.showReport ) {
        this.setState( {
          showReport : this.props.showReport
        } )

        if( this.props.showReport ) {
          //etTimeout( (e)=> {
            if( document.getElementById('chart_container') ){
              var rect =  document.getElementById('chart_container').getBoundingClientRect()
              _this.setState({
                selectedTab : "overview",
                selectLog : 'nested',
                showConfirm : false,
                w : rect.width ,
                h : Math.max(rect.height - 40 , 0) ,
                transaction_fee : _this.props.group.transaction_fee,
                initial_capital : _this.props.group.initial_capital
              })
            }
            if( !_this.state.hasReport ) {
              if(props.report) {
                if( props.report.length > 0 ) {
                  if( typeof( props.report ) == 'object' ) {
                    try {
                      _this.calcpTemp(props.report, props.group.initial_capital, props.group.transaction_fee)
                      _this.setState({
                        selectedTab : "overview",
                        selectLog : 'nested',
                        transaction_fee : props.group.transaction_fee,
                        initial_capital : props.group.initial_capital,
                        hasReport : true,
                        isOwner : props.isOwner,
                        report : props.report,
                      })
                    }
                    catch(e) {
                      //console.log(e)
                    }
                  }
                }
              } else {
                if(  _this.props.group[this.props.type + "_report"] && _this.props.showReport ) {
                  _this.getReport(props)
                  _this.setState({
                    transaction_fee : _this.props.group.transaction_fee,
                    initial_capital : _this.props.group.initial_capital,
                    hasReport : true,
                    isOwner : _this.props.isOwner,
                  })
                }
              }
            } else {
              if(_this.props.report) {
                if( _this.props.report.length > 0 ) {
                  _this.calcpTemp( _this.props.report, _this.props.group.initial_capital ,_this.props.group.transaction_fee)
                }
              }
            }
          //} , 100)
        }
      }
      
  }

  calcpTemp(sp, initial_capital, transaction_fee ) {
    //console.log()
    // NEW BACKTEST REPORT
    initial_capital = parseFloat( initial_capital, 10 )
    transaction_fee = parseFloat( transaction_fee, 10 )
    var dmax=0, dmin=0, profit = 0, backtest_report={}
    var profits = []
    backtest_report.initial_capital = initial_capital
    backtest_report.trade_count = 0
    backtest_report.activeGroup = {}
    backtest_report.win_count = 0
    backtest_report.loss_count = 0
    backtest_report.even_count = 0
    backtest_report.symbol_count = 0
    backtest_report.buyhand = 0
    backtest_report.sellhand = 0
    backtest_report.overdraft = false
    backtest_report.overdraft_date = null
    backtest_report.maxp = 0
    backtest_report.minp = 0
    backtest_report.dividends = 0
    backtest_report.profit_from_trades = 0
    var group = JSON.parse(  JSON.stringify( this.props.group ) )

    //NEW
    backtest_report.turnover_rate = this.props.group.turnover_rate
    backtest_report.profit_from_wins = 0
    backtest_report.deficit_from_losses = 0
    backtest_report.annual_return = 0
    backtest_report.annual_return = 0
    backtest_report.period_days = 0
    backtest_report.beta = 0
    backtest_report.average_onmarket = 0
    backtest_report.average_reserved_rate = 0
    backtest_report.true_return_from_botfolio = 0
    //NEW


    var cash = initial_capital, onstock = {}, safety = {}
    var _objs={}, _objt={}, _objp={}, _objst={}
    var ttl = initial_capital

    //NEW
    var _durations = []
    for (var g in group['partition']) {
      _objst[ g ] = { profit : 0, onhold : 0, sold : 0 }
      onstock[g] = 0
      backtest_report.activeGroup[g]=0
    }
 
    
    var dividends = 0
    for (var i in sp) {

      if( !(sp[i].date in _objt) ) { 
        _objt[ sp[i].date  ] = {'date': sp[i].date , 'profit':0, 'actions':[], 'cash':0, 'onstock':{} , 'valid' : {}, 'dividends' : 0 } 
      } 

      if( sp[i].decision !== 0 ) {
        
        backtest_report.trade_count += 1
        backtest_report.activeGroup[ sp[i].group  ]  = 1
        var _p = ( sp[i].price * sp[i].boardlot) * Math.abs( sp[i].decision  )
        var profit = -(  transaction_fee )
        var ttl_fee =  transaction_fee

        if( (sp[i].symbol in _objs) && (sp[i].decision < 0) ) {
          profit = ( ( sp[i].price - _objs[sp[i].symbol][ sp[i].group].price ) ) * sp[i].boardlot * Math.abs( sp[i].decision  ) -  ttl_fee
          //NEW
          var _s = new Date(_objs[ sp[i].symbol ][ sp[i].group ].date)
          var _n = new Date( sp[i].date )
          var _d = (_n - _s)/1000/60/60/24
          _durations.push( _d )
          //NEW

        }

        if( !(sp[i].symbol in _objs) ) {
          _objs[sp[i].symbol] = {}
          _objp[sp[i].symbol] = {}
          for (var g in group['partition']) {
            _objs[sp[i].symbol][g] = { price : 0, boardlot : 0, date : 0  }
            _objp[sp[i].symbol][g] = { profit : 0, quantity : 0  }
          }
        }
        if( sp[i].decision > 0 ) {
          backtest_report.buyhand += 1
          _objs[sp[i].symbol][ sp[i].group].price = ((_objs[sp[i].symbol][ sp[i].group].price * _objs[sp[i].symbol][ sp[i].group].boardlot ) + ( sp[i].price * sp[i].boardlot * Math.abs( sp[i].decision  )  ) )/( (sp[i].boardlot * Math.abs( sp[i].decision  )) +  _objs[sp[i].symbol][ sp[i].group].boardlot )
          _objs[sp[i].symbol][ sp[i].group].boardlot += ( sp[i].boardlot * Math.abs( sp[i].decision  ) )
          _objs[sp[i].symbol][ sp[i].group].date = sp[i].date
          cash -= ( _p + ttl_fee )
          onstock[sp[i].group] += _p
          _objst[ sp[i].group ].onhold += 1
        } else {
          backtest_report.sellhand += 1
          _objs[sp[i].symbol][ sp[i].group].boardlot -= sp[i].boardlot * Math.abs( sp[i].decision  )
          onstock[sp[i].group] -= _objs[sp[i].symbol][ sp[i].group].price * sp[i].boardlot * Math.abs( sp[i].decision  )
          cash += ( _p - ttl_fee )
          if( profit > 0 ) {
            backtest_report.win_count += 1
          } else if( profit === 0 ) {
            backtest_report.even_count += 1
          } else {
            backtest_report.loss_count += 1
          }
          _objp[sp[i].symbol][g].profit += profit
          _objp[sp[i].symbol][g].quantity += 1
          _objst[ sp[i].group ].profit += profit
          _objst[ sp[i].group ].onhold -= 1
          _objst[ sp[i].group ].sold += 1
          profits.push( profit/ttl )

        }

        //if( !(sp[i].date in _objt) ) { 
        //  _objt[ sp[i].date  ] = {'date': sp[i].date , 'profit':0, 'actions':[], 'cash':0, 'onstock':{} , 'valid' : {}, 'dividends' : 0 } 
        //} 
        _objt[ sp[i].date  ]['profit'] += profit

        if( sp[i].dividends ) {
          _objt[ sp[i].date  ]['dividends'] += sp[i].dividends
        } 

        sp[i].profit = profit
        _objt[ sp[i].date  ]['actions'].push(  sp[i] )
        _objt[ sp[i].date  ]['cash'] = cash

        var total = cash
        var tstock = JSON.parse(JSON.stringify(onstock))
        var tsatefy = JSON.parse(JSON.stringify(safety))
        for (var s in tstock) {
          total += tstock[s]
        }
        //ttl = total
        for (var s in group['partition']) {
          safety[s] = total * parseFloat(  group['partition'][s]  , 10)/100
        }

        var overdrafts = JSON.parse( JSON.stringify( {} ) )
        var overdraft = false
        for (var s in tsatefy) {
          if(  s in tstock) {
            if(  tstock[s] > tsatefy[s] ) {
              overdrafts[ s ] = true
              overdraft = true
              if( !backtest_report.overdraft ) {
                backtest_report.overdraft = true
                backtest_report.overdraft_date = sp[i].date
              }
              
            } else {
              overdrafts[ s ] = false
            }
          }
        }
        _objt[ sp[i].date  ]['onstock'] =  JSON.parse( JSON.stringify(  onstock  )   )
        _objt[ sp[i].date  ]['overdrafts'] = overdrafts
        _objt[ sp[i].date  ]['overdraft'] = overdraft
        ttl = total      
      } else {
        if( sp[i].dividends ) {
          dividends += sp[i].profit
          cash += ( sp[i].profit )
          _objt[ sp[i].date  ]['dividends'] += sp[i].profit
          _objt[ sp[i].date  ]['onstock'] =  JSON.parse( JSON.stringify(  onstock  )   )
          _objt[ sp[i].date  ]['cash'] =  cash
          _objt[ sp[i].date  ]['actions'].push( sp[i] )
        }
      }
    }
  
    

    var pcash = initial_capital
    var data = [], dates = [], onmarkets=[], cash_reserved = []
    
    //NEW
    var _pure_profit = 0
    var onstocks = []
    var _tcash = []


    for (var t in _objt) {
      var d = service.formatDate( t, false, true )
      dates.push( d )
      _objt[t].date = d
      var total = _objt[t].cash + _objt[t].dividends
      var _onstock = 0
      for (var v in _objt[t].onstock) {
        total += _objt[t].onstock[v]
        _onstock += _objt[t].onstock[v]
      }
      _objt[t].rate = ((total-pcash) /pcash * 100 )
      if( total ) {
        dmax = Math.max( ((total-pcash) /pcash * 100 ), dmax )
        dmin = Math.min( ((total-pcash) /pcash * 100 ), dmin )
        var orimx = backtest_report.maxp, orimn = backtest_report.minp
        backtest_report.maxp = Math.max( (total-pcash), backtest_report.maxp )
        backtest_report.minp = Math.min( (total-pcash), backtest_report.minp )
        if( (backtest_report.maxp  === (total-pcash)) && ( backtest_report.maxp!==orimx ) ) {
          backtest_report.mxdate = _objt[t].date
        }
        if( (backtest_report.minp  === (total-pcash)) && ( backtest_report.minp!==orimn ) ) {
          backtest_report.mndate = _objt[t].date
        }
      }
      data.push(  _objt[t]  )
      //NEW
      var _v = maths.sum(  Object.values( _objt[t].onstock ) )
      onmarkets.push(  _v  )
      cash_reserved.push(  _objt[t].cash/total )
      
      var pure_actions = _objt[t].actions.filter( (v,k) => (v.decision!==0) )
      _pure_profit += (  _objt[t].profit + transaction_fee * pure_actions.length  )

      onstocks.push(  {
        x : new Date(t),
        y : _onstock
      }  )

      _tcash.push(  {
        x : new Date(t),
        y : _objt[t].cash
      }  )
    }

  
    var std = (profits.length>0)?maths.std(profits):0
    var mean =(profits.length>0)? maths.mean(profits):0
    backtest_report.profit = (total - pcash)
    backtest_report.std_dev = std
    backtest_report.mean = mean

    var strategy = [], mx = 0, mn = 0, l = 0
    for (var st in _objst ) {
      _objst[st].group = st
      strategy.push( _objst[st]  )
      mx = Math.max( _objst[st].profit, mx )
      mn = Math.min( _objst[st].profit, mn )
    }
    l = mx - mn
    var strange = { 
      l : l,
      y0 : 0 , 
      y1:( mx - Math.min(mn, 0) ), 
      dyu : -Math.min(mn, 0),
      dyd : mx,
      max : mx,
      min : mn,
    }
    strategy = strategy.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    var mx = 0, mn = 0, l = 0
    var symbolpw = [], symbolpl = [], _tp = {}
    for (var sym in _objp) {
      backtest_report.symbol_count += 1
      _tp = {symbol : sym, profit : 0, quantity : 0}
      for (var g in _objp[sym]) {
        _tp.profit += _objp[sym][g].profit
        _tp.quantity += _objp[sym][g].quantity 
      }
      mx = Math.max( _tp.profit, mx )
      mn = Math.min( _tp.profit, mn )
      l = Math.max(  Math.abs(_tp.profit) , l )
      if( _tp.profit >= 0) {
        symbolpw.push(  _tp  )
      } else {
        symbolpl.push(  _tp  )
      }
    }
    symbolpw = symbolpw.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    symbolpl = symbolpl.sort(function(a, b) {
      return parseFloat(a.profit) - parseFloat(b.profit);
    });

    //NEW
    try {
      backtest_report.profit_from_wins = Math.round( symbolpw.map(o => o.profit).reduce( (a,b) =>{ return a+b } ) * 100 )/100
    }
    catch(e) {

    }
    try {
      
      backtest_report.deficit_from_losses = Math.round( symbolpl.map(o => o.profit).reduce( (a,b) =>{ return a+b } ) * 100 )/100
    }
    catch(e) {

    }
    try {
      var _dates = Object.keys( _objt )
      var d1 = new Date(_dates[ 0 ])
      var d2 = new Date(_dates[ _dates.length-1 ] )
      var day_diff = (d2-d1)/3600/24/1000
      backtest_report.period_days = day_diff
      var _return = total/initial_capital
      backtest_report.annual_return = (Math.pow( _return , TRADE_DAYS/day_diff ) - 1)
    } catch(e) {
    }
    try {
      backtest_report.average_onmarket = maths.mean(  onmarkets  )
    } catch(e) {
    }
    try {
      backtest_report.average_reserved_rate = maths.mean(  cash_reserved  )
    } catch(e) {
    }
    //NEW

    var srange = { 
      l : l,
      x0 : 0 , 
      x1:( mx - Math.min(mn, 0) ), 
      dxl : -Math.min(mn, 0),
      dxr : mx,
    }
    var snames = {}
    for (var idx in this.props.group.strategy_list) {
      snames[ this.props.group.strategy_list[idx].id ] = this.props.group.strategy_list[idx].name
    }

    backtest_report.total_trading_days = data.length
    backtest_report.strategy_count = 0
    for (var i in  backtest_report.activeGroup) {
      if( backtest_report.activeGroup[i] === 1 ) {
        backtest_report.strategy_count += 1
      }
    }

    //NEW
    backtest_report.true_return_from_botfolio = _pure_profit
    backtest_report.average_trade_time = (_durations.length>0)?maths.mean(  _durations ):0

    backtest_report.profit_from_trades = backtest_report.profit - dividends
    backtest_report.dividends = dividends

    var r_data = JSON.parse( JSON.stringify( data ) )
    r_data = r_data.sort( (u,v) => {
      var a = new Date(v.date)
      var b = new Date(u.date)
      return a-b
    } )

    this.setState({
        objt : _objt,
        domain : [ dmin*1.25, dmax*1.25 ],
        data : data,
        r_data : r_data,
        strategy : strategy,
        symbolpw : symbolpw,
        symbolpl : symbolpl,
        srange : srange,
        strange : strange, 
        snames : snames,
        backtest_report : backtest_report,
        dates : dates,
        onstock : onstocks,
        cash : _tcash,
        records : sp,
    })

    //if(  (dates.length > 0) && this.state.dates.length === 0 ) {
      if(  (dates.length > 0) ) {
      var _dates = []
      for (var i in dates) {
        _dates.push(  service.formatDate(  dates[i]  )   )
      }
      //HERE
      var region = this.props.group.region?this.props.group.region:"usa"
      var bm_list = BRENCHMARK[region]

      this.getBMPrice( bm_list[0].key , _dates, backtest_report, _objt )
    }

  }

  calcpReport( report ) {
    var sp = report.trade_list
    var initial_capital = parseFloat( report.initial_capital, 10 )
    var transaction_fee = parseFloat( report.transaction_fee, 10 )

    this.calcpTemp(sp,initial_capital,transaction_fee )
    /*
    var dmax=0, dmin=0, profit = 0
    var backtest_report = report
    backtest_report.maxp = 0
    backtest_report.minp = 0
    backtest_report.buyhand = 0
    backtest_report.sellhand = 0
    //NEW
    backtest_report.turnover_rate = 0
    backtest_report.deficit_from_losses = 0
    backtest_report.annual_return = 0
    backtest_report.period_days = 0
    backtest_report.beta = 0
    backtest_report.average_onmarket = 0
    backtest_report.average_reserved_rate = 0
    backtest_report.true_return_from_botfolio = 0
    backtest_report.dividends = 0
    backtest_report.profit_from_trades = 0
    //NEW
    var profits = []
    var group = JSON.parse(  JSON.stringify( this.props.group ) )

    var cash = initial_capital, onstock = {}, safety = {}
    var _objs={}, _objt={}, _objp={}, _objst={}
    var ttl = initial_capital
    var _durations = []
 
    for (var g in group['partition']) {
      _objst[ g ] = { profit : 0, onhold : 0, sold : 0 }
      onstock[g] = 0
    }
 
    for (var i in sp) {
      var _p =  ( sp[i].price ) * Math.abs( sp[i].decision  )  *  sp[i].boardlot 
      var profit = -(transaction_fee)
      if( (sp[i].decision < 0) ) {
        //profit = (( ( sp[i].profit ) * Math.abs( sp[i].decision  ) ) +  Math.abs( sp[i].decision  ) * transaction_fee ) -  (transaction_fee)
        profit += sp[i].profit 
        //NEW
        var _s = new Date(_objs[ sp[i].symbol ][ sp[i].group ].date)
        var _n = new Date( sp[i].date )
        var _d = (_n - _s)/1000/60/60/24
        _durations.push( _d )
        //NEW

      }

      if( !(sp[i].symbol in _objs) ) {
        _objs[sp[i].symbol] = {}
        _objp[sp[i].symbol] = {}
        for (var g in group['partition']) {
          _objs[sp[i].symbol][g] = { price : 0, boardlot : 0, date : 0  }
          _objp[sp[i].symbol][g] = { profit : 0, quantity : 0  }
        }
      }
      if( sp[i].decision > 0 ) {
        backtest_report.buyhand += 1
        _objs[sp[i].symbol][ sp[i].group].price = ((_objs[sp[i].symbol][ sp[i].group].price * _objs[sp[i].symbol][ sp[i].group].boardlot ) + ( sp[i].price * sp[i].boardlot * Math.abs( sp[i].decision  )  ) )/( (sp[i].boardlot * Math.abs( sp[i].decision  )) +  _objs[sp[i].symbol][ sp[i].group].boardlot )
        _objs[sp[i].symbol][ sp[i].group].boardlot += ( sp[i].boardlot * Math.abs( sp[i].decision  ) )
        _objs[sp[i].symbol][ sp[i].group].date = sp[i].date
        cash -= ( _p + transaction_fee )
        onstock[sp[i].group] += _p
        _objst[ sp[i].group ].onhold += 1
      } else if( sp[i].decision < 0 ){
        backtest_report.sellhand += 1
        _objs[sp[i].symbol][ sp[i].group].boardlot -= sp[i].boardlot * Math.abs( sp[i].decision  )
        onstock[sp[i].group] -= _objs[sp[i].symbol][ sp[i].group].price * sp[i].boardlot * Math.abs( sp[i].decision  )
        cash += (_p - transaction_fee)
        _objp[sp[i].symbol][g].profit += profit
        _objp[sp[i].symbol][g].quantity += 1
        _objst[ sp[i].group ].profit += profit
        _objst[ sp[i].group ].onhold -= 1
        _objst[ sp[i].group ].sold += 1
        profits.push( profit/ttl )
      } else {
      }

      if( !(sp[i].date in _objt) ) { 
        _objt[ sp[i].date  ] = {'date': sp[i].date , 'profit':0, 'actions':[], 'cash':0, 'onstock':{} , 'valid' : {} } 
      } 
      

      if( sp[i].decision!== 0 ) {
        _objt[ sp[i].date  ]['profit'] += parseFloat(profit, 10)
        _objt[ sp[i].date  ]['actions'].push(  sp[i] )
        _objt[ sp[i].date  ]['cash'] = cash
        var total = cash
        var tstock = JSON.parse(JSON.stringify(onstock))
        var tsatefy = JSON.parse(JSON.stringify(safety))
        for (var s in tstock) {
          total += tstock[s]
        }
        for (var s in group['partition']) {
          safety[s] = total * parseFloat(  group['partition'][s]  , 10)/100
        }
        _objt[ sp[i].date  ]['onstock'] =  JSON.parse( JSON.stringify(  onstock  )   )
      } else {
        _objt[ sp[i].date  ]['profit'] += parseFloat( sp[i].profit, 10)
        _objt[ sp[i].date  ]['actions'].push(  sp[i] )
        _objt[ sp[i].date  ]['cash'] = cash
      }



    }
    
    var pcash = initial_capital
    var data = [], dates = [], onmarkets = [], cash_reserved = []

    var _pure_profit = 0
    var onstocks = []
    var _tcash = []


    for (var t in _objt) {
      var d = service.formatDate( t, false, true )
      _objt[t].date = d
      dates.push(d)
      var total = _objt[t].cash
      var _onstock = 0
      for (var v in _objt[t].onstock) {
        total += _objt[t].onstock[v]
        _onstock += _objt[t].onstock[v]
      }

      _objt[t].rate = ((total-pcash) /pcash * 100 )

      if( total ) {
        dmax = Math.max( ((total-pcash) /pcash * 100 ), dmax )
        dmin = Math.min( ((total-pcash) /pcash * 100 ), dmin )
        var orimx = backtest_report.maxp, orimn = backtest_report.minp
        backtest_report.maxp = Math.max( (total-pcash), backtest_report.maxp )
        backtest_report.minp = Math.min( (total-pcash), backtest_report.minp )
        if( (backtest_report.maxp  === (total-pcash)) && ( backtest_report.maxp!==orimx ) ) {
          backtest_report.mxdate = _objt[t].date
        }
        if( (backtest_report.minp  === (total-pcash)) && ( backtest_report.minp!==orimn ) ) {
          backtest_report.mndate = _objt[t].date
        }
      }
      data.push(  _objt[t]  )
      var _v = maths.sum(  Object.values( _objt[t].onstock ) )
      onmarkets.push( Math.abs( _v )  )

      var ratio = 1-( Math.abs(_v)/_objt[t].cash )
      cash_reserved.push(  ratio  )

      _pure_profit += (  _objt[t].profit + transaction_fee * _objt[t].actions.length  )
      onstocks.push(  {
        x : new Date(t),
        y : _onstock
      }  )

      _tcash.push(  {
        x : new Date(t),
        y : _objt[t].cash
      }  )
    }


    var strategy = [], mx = 0, mn = 0, l = 0
    for (var st in _objst ) {
      _objst[st].group = st
      strategy.push( _objst[st]  )
      mx = Math.max( _objst[st].profit, mx )
      mn = Math.min( _objst[st].profit, mn )
    }
    l = mx - mn
    var strange = { 
      l : l,
      y0 : 0 , 
      y1:( mx - Math.min(mn, 0) ), 
      dyu : -Math.min(mn, 0),
      dyd : mx,
      max : mx,
      min : mn,
    }
    strategy = strategy.sort(function(a, b) {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    var mx = 0, mn = 0, l = 0
    var symbolpw = [], symbolpl = [], _tp = {}

    for (var sym in _objp) {
      _tp = {symbol : sym, profit : 0, quantity : 0}
      for (var g in _objp[sym]) {
        _tp.profit += _objp[sym][g].profit
        _tp.quantity += _objp[sym][g].quantity 
      }
      mx = Math.max( _tp.profit, mx )
      mn = Math.min( _tp.profit, mn )
      l = Math.max(  Math.abs(_tp.profit) , l )
      if( _tp.profit >= 0) {
        symbolpw.push(  _tp  )
      } else {
        symbolpl.push(  _tp  )
      }
    }
    symbolpw = symbolpw.sort( (a, b) => {
      return parseFloat(b.profit) - parseFloat(a.profit);
    });
    symbolpl = symbolpl.sort( (a, b) => {
      return parseFloat(a.profit) - parseFloat(b.profit);
    });

    //NEW
    try {
      //console.log( symbolpw  ) 
      backtest_report.profit_from_wins = Math.round( symbolpw.map(o => o.profit).reduce( (a,b) =>{ return a+b } ) * 100 )/100
    } catch(e) {

    }
    try {
      backtest_report.deficit_from_losses = Math.round( symbolpl.map(o => o.profit).reduce( (a,b) =>{ return a+b } ) * 100 )/100
    } catch(e) {
    }
    try {
      var _dates = Object.keys( _objt )
      var d1 = new Date(_dates[ 0 ])
      var d2 = new Date(_dates[ _dates.length-1 ] )
      var day_diff = (d2-d1)/3600/24/1000
      backtest_report.period_days = day_diff
      var _return = 1 + group.profit_rate/100
      backtest_report.annual_return = (Math.pow( _return , TRADE_DAYS/day_diff ) - 1)
    } catch(e) {
    }
    try {
      backtest_report.average_onmarket = Math.abs( maths.mean(  onmarkets  ) )
    } catch(e) {
    }
    try {
      backtest_report.average_reserved_rate = maths.mean(  cash_reserved  )
    } catch(e) {
    }
    
    //NEW
    backtest_report.true_return_from_botfolio = _pure_profit
    backtest_report.average_trade_time = (_durations.length>0)?maths.mean(  _durations ):0


    var srange = { 
      l : l,
      x0 : 0 , 
      x1:( mx - Math.min(mn, 0) ), 
      dxl : -Math.min(mn, 0),
      dxr : mx,
    }
    var snames = {}
    for (var idx in this.props.group.strategy_list) {
      snames[ this.props.group.strategy_list[idx].id ] = this.props.group.strategy_list[idx].name
    }

    if(  (dates.length > 0) && this.state.dates.length === 0 ) {
      var _dates = []
      for (var i in dates) {
        _dates.push(  service.formatDate(  dates[i]  )   )
      }

      apis.getprices( this.state.branchmark , "history", _dates  ).then(
        (res) => {
          var arr = [], base = 0, x = [], y=[]
          //console.log(res)
          for (var i in res) {
            if( parseInt(i, 10) === 0 ) {
              base = res[i].close
            }
            var obj = {}
            obj.x = new Date(res[i].date)
            obj.y = (res[i].close - base)/base * 100
            arr.push(obj)

            var _date = service.formatDate( res[i].date, true )
            x.push( obj.y  )
            var _r = 0
            if( _date in _objt ) {
              _r = _objt[_date].rate*100
            }
            y.push( _r )

          }
          backtest_report.beta = this.corr(x, y)
          this.setState({bm : arr, backtest_report : backtest_report})
        }
      )
    }

    backtest_report.turnover_rate = this.props.group.turnover_rate

    var r_data = JSON.parse( JSON.stringify( data ) )
    r_data = r_data.sort( (u,v) => {
      var a = new Date(v.date)
      var b = new Date(u.date)
      return a-b
    } )

    this.setState({
        domain : [ dmin*1.25, dmax*1.25 ],
        data : data,
        r_data : r_data,
        strategy : strategy,
        symbolpw : symbolpw,
        symbolpl : symbolpl,
        srange : srange,
        strange : strange, 
        snames : snames,
        backtest_report : backtest_report,
        dates : dates,
        onstock : onstocks,
        cash : _tcash,
        records : sp,
    })
    */
  }

  getBMPrice( symbol , _dates, backtest_report, _objt={} ) {
    var region = this.props.group.region?this.props.group.region:"usa"
    var bm_list = BRENCHMARK[region]
    var selected = bm_list.filter( (v,k) => (v.key === symbol) )
    apis.getprices( symbol , "history", _dates  ).then(
      (res) => {
        var arr = [], base = 0
        var x = [], y =[]
        for (var i in res) {
          if( parseInt(i, 10) === 0 ) {
            base = res[i].close
          }
          var obj = {}
          obj.x = new Date(res[i].date)
          obj.y = (res[i].close - base)/base * 100

          var _date = service.formatDate( res[i].date, true )
          x.push( obj.y  )
          var _r = 0
          if( _date in _objt ) {
            _r = _objt[_date].rate*100
          }
          y.push( _r )
          arr.push(obj)
        }
        backtest_report.beta = this.corr(x,y)
        this.setState({
            bm : arr, 
            backtest_report : backtest_report, 
            bm_list: bm_list , 
            branchmark : selected[0]   
        })
      }
    )
  }

  corr(x, y) {
      let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
      const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi, idx) => {
          const yi = y[idx];
          sumX += xi;
          sumY += yi;
          sumXY += xi * yi;
          sumX2 += xi * xi;
          sumY2 += yi * yi;
        }
      x.forEach(reduce);
      return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
  }

  getReport(props) {
    //var bool = (props.type==="forwardtest")
    var pid = ( "subscription" in props.group )?props.group["subscription"].portfolio_id:props.group.id
    service.getReport( pid , props.group[props.type + "_report"].id  ).then(
      (res) => {
          this.setState( {
            report : res.trade_list
          } )
          this.calcpReport( res  )
      }
    )
  }

  sortdata(key, value) {
    this.setState({
      symData : [],
      points : [],
    })
    //console.log( key + "===" +  this.state.selected.key )
    //console.log( value + "===" +  this.state.selected.value )
    if(  (key !== this.state.selected.key) && (value !== this.state.selected.value) ) {
      //var arr = JSON.parse(  JSON.stringify( this.props.report )  )
      var arr = JSON.parse(  JSON.stringify( this.state.report )  )
      arr = arr.filter(function(a) {
        return a[key] === value
      })
      this.setState({
        selected : {key : key, value : value}
      })    
      this.calcpTemp(arr, this.props.group.initial_capital, this.props.group.transaction_fee)
      if( key === "symbol" ){
        this.selectSym( value )
      }
    } else {
      this.setState({
        selected : {key : null, value : null}
      })
      this.calcpTemp(this.state.report, this.props.group.initial_capital, this.props.group.transaction_fee)

    }
  }

  generateChild(v, k) {
    const { colors } = this.props
    //var keys = ['date', 'symbol', 'price', 'decision' ,'profit' , 'boardlot']
    var keys = [ 'symbol', 'sname', 'price', 'decision' ,'profit' , 'boardlot']
    return(
        v.actions.map( (v2,k2) => {
        return(
              <tr key={k +"_" +k2} className={[ 'D-Record', "show"  ].join(" ") } >
                {
                  keys.map( (v3, k3) => {
                    var val2 = ""
                    //if( v3 === 'date' ) {
                      //val2 = service.formatDate( v2[v3] )
                      //val2 = ""
                    var sobj ={}
                    if( v3 === 'sname' ) {
                      val2 = (v2['symbol'] in this.props.sym_names)?this.props.sym_names[ v2['symbol']  ][this.props.language]:""
                    } else if( ( v3  === 'profit') || ( v3  === 'price')  ) {
                      val2 = Math.round( v2[v3] * 1000 )/1000

                      if ( v3  === 'profit') {
                          if( v2.dividends ) {
                            val2 += v2.dividends
                          }

                          if( v2[v3] > 0 ) {
                            sobj.backgroundColor = colors.gnGreenColor + "40"
                          } else if( v2[v3] < 0 ) {
                            sobj.backgroundColor  = colors.gnRedColor + "40"
                          }

                      }

                    } else if (v3 === 'decision') {
                      if( v2[v3] === 0 ) {
                        //val2 =  "Dividends : $" + v2.dividends + "/Share"
                        val2 = this.props.gi18n[this.props.language]['t_dividends'].replace("<dividends>", v2.dividends)
                      } else {
                        val2 = ((v2[v3]>0)?(this.props.gi18n[this.props.language]['t_buy'] + " : "  ):(this.props.gi18n[this.props.language]['t_sell'] + " : "  ) ) + Math.abs( v2[v3] )
                      }
                    } else {
                      val2 = v2[ v3  ]
                    }
                    return(
                      <td 
                        style={ sobj }
                        className={ ['detail' ].join(" ") }
                        key={k2+"_"+k3}>{ val2 }</td> 
                    )
                  })
                }
              </tr>
      )
      })
    )
  }

  hideAll() {
    this.setState({
      hasReport : false,
      domain : [-1,1],
      w : 0,
      h : 0,
      data : [],
      symbolp : [],
      symbolpw : [],
      symbolpl : [],
      strategy : [],
      srange : {x1:0,x0:0,l:0, dxr:0, dxl:0},
      strange : {y1:0,y0:0,l:0, dyu:0, dyd:0, max : 0, min : 0},
      showDate : null,
      snames : {},
      selected : {key:null, value:null},
      showbacktest_report : true,
      showHOverbacktest_report : false,
      backtest_report : {},
      transaction_fee : 0,
      initial_capital : 0,
      bm : [],
      dates : [],
      showConfirm : false,
      body : null,
    })
  }

  onsubmit() {
    if( this.props.submit) {
      this.saveReport()
    } else {
      this.setState( { showConfirm : true } )
    }
  }
  
  saveReport() {
    if( !this.state.backtest_report.overdraft ) {

      var report = JSON.parse(  JSON.stringify( this.state.report )  )
      //console.log( report )
      

      for (var i in report) {
        report[i].date = service.formatDate(  report[i].date, true  )
        report[i].group = report[i].group.toString()
      }
      
      var obj = {
        initial_capital : parseFloat(this.props.group.initial_capital, 10) ,
        transaction_fee : parseFloat(this.props.group.transaction_fee, 10),
        trade_list : report,
        report_type : 'backtest',
      }
      this.props.updateValue({ showLoading : true })

      service.createReport( this.props.group.id, obj ).then(
        (res) => {
          this.props.editGroup( 'backtest_report' , res  )
          this.exit()
          this.props.updateValue({
            showAlert : true,
            showAlertBtn : false,
            showLoading : false,
            alertMsg : this.props.gi18n[ this.props.language ]['submit_success']
          })
          this.props.save(null, false, false)
        }
      )

    } else {
      this.setState(
        {
          body : this.props.gi18n[this.props.language]['invalid_report']
        }
      )
    }
  }

  exit() {
    this.hideAll()
    this.props.updateValue( {  showReport  : false  }  )
  }

  selectSym(sym) {
    apis.getprices( sym, "history" ).then(
      (res) => {
        var prices = {}
        for (var i in res) {
          var _do = res[i].date.split("-")
          res[i].x = new Date( parseInt(_do[0], 10), parseInt(_do[1], 10)-1, parseInt(_do[2], 10) )
          prices[ _do[0] ] = res[i]
        }

        var first = res[0].x, last = res[ res.length-1 ].x

        var _pairs = []
        for (var j in this.state.data ) {
          var _d = this.state.data[j].actions[0].date.split(" ")
          _d = _d[0].split("-")
          _d = new Date(  parseInt( _d[0], 10 ) , parseInt( _d[1], 10 )-1 , parseInt( _d[2], 10 )  )
          if(  (_d >= first) && ( _d<=last )  ) {
            if ( this.state.data[j].actions[0].decision > 0  ) {
              var obj = {}
              obj.buy = this.state.data[j].actions[0].price
              obj.buydate = _d
              _pairs.push(  obj )
            } else {
              for (var k in _pairs) {
                if(  !_pairs[k].sell ) {
                  _pairs[k].sell = this.state.data[j].actions[0].price
                  _pairs[k].selldate = _d
                  _pairs[k].profit = (this.state.data[j].actions[0].price-_pairs[k].buy) * this.state.data[j].actions[0].boardlot * Math.abs(  this.state.data[j].actions[0].decision   )
                  break
                }
              }
            }
          }
        }
        //console.log(  _pairs )
        var _points = []
        for (var i in _pairs) {
          var _arr = []
          _arr.push(   { x : _pairs[i].buydate, y : _pairs[i].buy  }  )
          if( _pairs[i].sell ) {
            _arr.push(   { x : _pairs[i].selldate, y : _pairs[i].sell  }  )
            
          }
          _points.push(  {  data:_arr, profit : (_pairs[i].profit?_pairs[i].profit:0) } )
        }

        this.setState({
          symData : res,
          points : _points,
        })
      }
    )
  }

  download() {
    var data = JSON.parse(  JSON.stringify(  this.state.report   )  )
    data = data.map( (v,k) => {
      try {
        v['amt'] = parseFloat( v.boardlot, 10 ) * parseFloat( v.price, 10 ) * (-1 * parseFloat( v.decision, 10 ) )
        v['volume'] = parseFloat( v.boardlot, 10 ) * Math.abs(parseFloat( v.decision, 10 ))
        v['type'] = ((parseFloat( v.decision, 10 ) > 0)?"B":"S")
        delete v['boardlot']
        delete v['decision']
        delete v['group']
        return v
      }
      catch(e) {
        return v
      }
    } )
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    var name = this.props.group.name + "_records.xlsx"
    XLSX.writeFile(workbook, name);

  }

  update(key, val) {
    var obj = {}
    obj[key] = val
    try {
      if( ["initial_capital", "transaction_fee"].includes(key) ) {
        var { group, updateValue, report } = this.props
        if( val.substring(val.length-1, val.length) === ".")  {
          val = `${value}5`
        }
        var valid = service.validateNum( val.toString() )
        if( valid || val.length === 0 ) {
          var value = val
          if( val.length === 0 ) {
            value = 0
          }
          group[ key ] = parseFloat(value, 10)
          var initial_capital = (key==="initial_capital")?value:group["initial_capital"]
          var transaction_fee = (key==="transaction_fee")?value:group["transaction_fee"]
          updateValue({ selected : group})
          this.calcpTemp( report, initial_capital, transaction_fee)
          this.setState(obj)
        } 
        
      } else {
        
        this.setState(obj)
      }
    } catch(e) {
      this.setState(obj)
    }
  }

  render() {
    const {save, colors} = this.props
    const {initial_capital ,transaction_fee } = this.state
    return (
     <Modal
        size="lg"
        show={this.props.showReport}
        onHide={() => {
          if( this.props.readonly ) {
            this.exit()
          } else {
            this.onsubmit()
          }
        }}
        backdropClassName="R-Backdrop"
        dialogClassName={["R-Dialog"].join(' ')}
        className={['Garlican-Report'].join(' ')} >

        <Modal.Header className={ ['R-Header'].join( " " ) } closeButton={false}>
          <div className={['Frame'].join(" ")}>
            <div className={ ['btn-outline',"R-Btn"].join(' ') } onClick={
                (e)=>{
                  if( this.props.readonly || !save ) {
                    this.exit()
                  } else {
                    this.onsubmit()
                  }
                }
            }>
              {this.props.gi18n[this.props.language]['back'] }
            </div>

            <div className={['R-Title'].join(" ")} >  
              <span className={['Name'].join(" ")}> { this.props.group.name } </span>
              {
                ((this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language][ this.props.type+'ing']:'Report summary')
              }  
            </div>

          </div>
        </Modal.Header>
        
        { this.props.showReport &&
          <Modal.Body className={['Content',  this.state.showConfirm?"Lock":""  ].join(" ")} >
            
              <div className={['R-Panel'].join(" ")}> 
                {
                  tabs.map(
                    (v,k) => {
                      return(
                        <Button 
                          onClick={
                            (e)=>{
                              this.setState({ selectedTab : v.key })
                            }
                          }
                          className={['Btn', (this.state.selectedTab===v.key)?"selected":""].join(" ")} 
                          key={k}> { this.props.gi18n[this.props.language][v.value] } </Button>
                      )
                    }
                  )
                }

                <div className={['Log-Button-Group'].join(" ")}>
                  { (this.state.selectedTab === "log") && this.state.isOwner &&
                    <Button className={['Log-Button-Group', 'Log-Button'].join(' ')}  onClick={ (e)=>{ this.download() }} > {  this.props.gi18n[this.props.language]['download']  } </Button>
                  }
                  { (this.state.selectedTab === "log") &&
                    <DropdownButton className={['Log-Button-Group','Log-Dropbox'].join(' ')}  title={ this.props.gi18n[this.props.language][  this.state.selectLog ]  + ("("+  ((this.state.selectLog==='nested')?this.state.data.length:this.state.records.length) +")")   } >
                      <Dropdown.Item as="button" onClick={ (e)=>{ this.setState({  selectLog : 'nested'  }) }} > { this.props.gi18n[this.props.language]['nested'] + ("("+ this.state.data.length +")")  } </Dropdown.Item>
                      <Dropdown.Item as="button" onClick={ (e)=>{ this.setState({  selectLog : 'listed'  }) }}> { this.props.gi18n[this.props.language]['listed'] + ("("+ this.state.records.length +")")  }</Dropdown.Item>
                    </DropdownButton>
                  }
                </div>
              </div>
              

                <div className={['Body', this.state.selectedTab==="overview"?'Selected':""].join(" ")}>
                  <div className={['Left', this.state.showChart?'dispear':'hide'  ].join(" ")}> 

                    <div className={['Valid'].join(" ")} >
                      <div className={['V-Title'].join(" ")} > { this.props.gi18n[this.props.language]["botfolio_validation"] }  </div>
                      <div className={['V-Holder', this.state.backtest_report.overdraft?'Fail':"Pass"].join(" ")} >
                        {
                          !this.state.backtest_report.overdraft &&
                          <FaCheck className={['Fa'].join(" ")} />
                        }
                        {
                          this.state.backtest_report.overdraft &&
                          <FaTimes className={['Fa'].join(" ")} />
                        }
                      </div>
                    </div>

                    {
                      contents.map( (v,k) => {
                        return (
                          <div className={['Section'].join(" ")} key={k}>
                            <div className={['S-Title'].join(" ")} > {  this.props.gi18n[this.props.language][ v.key ]  }  </div>
                            {
                              v.value.map(
                                (v1, k1) => {
                                  return (
                                    <div className={['Docker'].join(" ")} key={k1}>
                                      {
                                          v1.value.map( (v2, k2) => {
                                          var sobj = {}
                                          var value = this.state.backtest_report[ v2.key ]
                                          var sub = null
                                          if( v2.key === "profit") {
                                            value = "$" + Math.round(this.state.backtest_report.profit*100)/100 + "   (" + ((this.state.backtest_report.profit>0)?"+":"")  + Math.round( this.state.backtest_report.profit/this.state.backtest_report.initial_capital*1000)/10  + "%)"
                                            sobj.color = (this.state.backtest_report.profit>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report.profit<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                            sobj.fontSize = "150%"
                                          } else if( v2.key === "dividends"  || v2.key === "profit_from_trades" ) {
                                              value = "$" + Math.round(this.state.backtest_report[v2.key]*100)/100
                                              sobj.color = (this.state.backtest_report[v2.key]>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report[v2.key]<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                              sobj.fontSize = "150%"
                                          } else if( v2.key === "avg_profit") {
                                            value = Math.round( this.state.backtest_report.mean*100000)/1000  + "%"
                                            sobj.color = (this.state.backtest_report.profit>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report.profit<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                          } else if( v2.key === "max_profit") {
                                            value = ("$" + Math.round(this.state.backtest_report.maxp*100)/100)
                                            sub = "@"+ service.formatDate( this.state.backtest_report.mxdate )
                                            sobj.color = colors.gnGreenColor
                                          } else if( v2.key === "min_profit") {
                                            value = ("$" + Math.round(this.state.backtest_report.minp*100)/100)
                                            sub =  "@"+ service.formatDate( this.state.backtest_report.mndate )
                                            sobj.color = colors.gnRedColor
                                          } else if( v2.key === "volatility") {
                                            value = Math.round(this.state.backtest_report.std_dev*100000)/1000 + "%"
                                          } else if( v2.key === "sharpe_ratio") {
                                            value = (this.state.backtest_report.std_dev>0)?(Math.round(this.state.backtest_report.mean/this.state.backtest_report.std_dev* 1000)/1000):"--"
                                          } else if (v2.key === "win_rate") {
                                            value = Math.round( this.state.backtest_report.win_count*100000/(this.state.backtest_report.win_count + this.state.backtest_report.even_count + this.state.backtest_report.loss_count) ) /1000 + "%"
                                          } else if (v2.key === "profit_from_wins") {
                                            value = "$" + parseFloat( value, 10).toLocaleString("en-US")
                                          } else if (v2.key === "deficit_from_losses") {
                                            value = "$" + parseFloat( value, 10).toLocaleString("en-US")
                                          } else if (v2.key === "annual_return") {
                                            value = Math.round( this.state.backtest_report.annual_return*10000)/100 + "%"
                                            sobj.color = (this.state.backtest_report.annual_return>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report.annual_return<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                          } else if (v2.key === "beta") {
                                            value = Math.round( this.state.backtest_report.beta*100)/100 
                                            sobj.color = (this.state.backtest_report.beta>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report.beta<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                          } else if (v2.key === "average_reserved_rate") {
                                            value = Math.round( this.state.backtest_report.average_reserved_rate*10000)/100 + "%"
                                          } else if (v2.key === "average_onmarket") {
                                            value = "$"+ Math.round( this.state.backtest_report.average_onmarket*100)/100
                                          } else if (v2.key === "true_return_from_botfolio") {
                                            value = "$"+Math.round( this.state.backtest_report.true_return_from_botfolio*100)/100
                                            sobj.color = (this.state.backtest_report.true_return_from_botfolio>0)?(colors.gnGreenColor+"FF"):((this.state.backtest_report.true_return_from_botfolio<0)?(colors.gnRedColor+"FF"):colors.gnTextColor)
                                          } else if (v2.key === "average_trade_time") {
                                            value = Math.round( this.state.backtest_report.average_trade_time*10)/10 + this.props.gi18n[this.props.language]['day']
                                          }
                                          
                                          return(
                                            <div 
                                              key={ k2 }
                                              className={ ['Item', (v1.value.length===2)?'Two':""].join(" ") }>
                                                <div className={['D-Holder'].join(" ")}>
                                                  <div className={['D-Title'].join(" ")}>{ (v2.key in this.props.gi18n[this.props.language])?this.props.gi18n[this.props.language][v2.key]:v2.key }</div>
                                                  { v2.type !== "input" &&
                                                    <div style={ sobj } className={['D-Value'].join(" ")}>{ 
                                                      value?value:"" 
                                                    }</div>
                                                  }
                                                  { v2.type === "input" && !this.props.readonly &&
                                                    <FormControl className={['D-Input'].join(" ")} value={ this.state[ v2.value ] } onChange={ (e)=>{
                                                      var o =  {}
                                                      o[ v2.value ] = e.target.value
                                                      //this.setState( o )
                                                      this.update(v2.value,e.target.value )
                                                    } }/>
                                                  }
                                                  { v2.type === "input" && !this.props.readonly &&
                                                    <div className={['D-Mask'].join(" ")}>{ "$"+ parseFloat( this.state[ v2.value ], 10 ).toLocaleString("en-US") }</div>
                                                  }
                                                  { v2.type === "input" && this.props.readonly &&
                                                    <div className={['D-Value'].join(" ")}>{ "$"+ parseFloat( this.state[ v2.value ], 10 ).toLocaleString("en-US") }</div>
                                                  }
                                                  { sub &&
                                                    <div className={['D-Sub'].join(" ")}>{ sub }</div>
                                                  }
                                                </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                }
                              )
                            }
                          </div>
                        )
                      } )
                    }


                    <div className={['Docker'].join(" ")} >
                      <div className={ ['Item', 'Two'].join(" ") }>

                      </div>
                      <div className={ ['Item', 'Two'].join(" ") }>
                        
                      </div>
                    </div>

                  </div>

                  <div className={['Middle-Panel'].join(" ")} onClick={ (e)=>this.setState({ showChart : !this.state.showChart  }) }>
                    {
                      this.state.showChart && <FaChevronLeft className={ ["Fa"].join(" ") }/>
                    }
                    {
                      !this.state.showChart && <FaChevronRight className={ ["Fa"].join(" ") }/>
                    }
                  </div>

                  <div className={['Right'].join(" ")}> 

                    <div className={['Upper'].join(" ")}> 
                      { (this.state.selected.key !== 'symbol') &&
                        <div className={['C-Title'].join(" ")}>{ this.props.gi18n[this.props.language]['profit_by_date'] }</div>
                      }
                      { (this.state.selected.key === 'symbol') &&
                          <div className={['C-Title'].join(" ")}>   
                            { this.state.selected.value  + " : " + this.props.gi18n[this.props.language]['profit_by_date']  }
                          </div>
                      }

                      {
                          (this.state.selected.key === 'symbol') &&
                          <Button 
                            onClick={  (e) => {
                                //NEW
                                this.sortdata( "symbol" , this.state.value )
                            } }
                            className={['Back'].join(' ')}> 
                            { this.props.gi18n[ this.props.language ]['back']  } 
                          </Button>
                      }

                      <div className={['C-Holder'].join(" ")} id={"chart_container"}>
                        { (this.state.selected.key !== 'symbol') && service.validateNum( initial_capital.toString() ) && service.validateNum( transaction_fee.toString() ) &&
                          <VictoryChart 
                            padding={ { top: fix, bottom: fix, left: fix, right : fix } }
                            width={ this.state.w  }
                            height={ Math.max((this.state.h - 30),0) }
                            containerComponent={
                            <VictoryZoomContainer
                              responsive={true} 
                              zoomDimension="x"
                            />
                          }>

                            <VictoryAxis 
                              domainPadding={{x: [0, 100]}}
                              tickFormat={ (t)=>service.formatDate( t )  }
                              style={{
                                axis: { 
                                  strokeWidth: 1, 
                                  strokeDasharray: 1,
                                  stroke: (colors.gnTextColor+"50")
                                },
                                ticks: { padding: 0 },
                                axisLabel : { 
                                  padding: (this.state.w>0)?-15:0,
                                  fontSize: 9,
                                  fontFamily: colors.gnFont,
                                  fill : colors.gnTextColor,
                                },
                                tickLabels: {
                                  fontSize: 9,
                                  fontFamily: colors.gnFont,
                                  fill : colors.gnTextColor,
                                  fillOpacity: 1,
                                  margin: 0,
                                  padding: 0
                                },
                              }}
                            />
                            <VictoryAxis dependentAxis  
                              domainPadding={{ y:[0, 1000] }}
                              label="Profit(%)"
                              style={{
                                axis: { 
                                  strokeWidth: 0,
                                },
                                ticks: { padding: -10 },
                                grid: {
                                  stroke: ({tick}) => (tick > 0)?(colors.gnGreenColor+"60"):(colors.gnRedColor+"60"), 
                                  strokeDasharray: 5,
                                },
                                axisLabel: {
                                  padding: (this.state.h>0)?-20:0,
                                  fontSize: 9,
                                  fontFamily: colors.gnFont,
                                  fill : colors.gnTextColor,
                                },
                                tickLabels: {
                                  fontSize: 9,
                                  fontFamily: colors.gnFont,
                                  fill : colors.gnTextColor,
                                  fillOpacity: 1,
                                  margin: 0,
                                  padding: 0
                                },
                              }}
                            />

                            <VictoryLine padding={0}
                              domain={ {y : this.state.domain } }
                              style={{
                                data: { 
                                  stroke: ({datum})=>(datum)?((!datum.overdraft)?(colors.gnFrame2Color+"90"):(colors.gnRedColor+"90")):colors.gnBadgeColor+"90",
                                },
                                parent: { 
                                  border: "0px solid #ccc"
                                },
                                labels: { 
                                  fill: colors.gnFrameColor, 
                                  fontSize: 12,
                                }
                              }}
                              labels={() => null}
                              data={ this.state.data }
                              x={ (datum) =>datum.date  }
                              y={ (datum) =>datum.rate  }
                            />

                            <VictoryLine padding={0}
                              style={{
                                data: { 
                                  stroke: colors.gnFrameColor+"99",
                                },
                                parent: { 
                                  border: "0px solid #ccc"
                                },
                                labels: { 
                                  fill: colors.gnFrameColor3, 
                                  fontSize: 0,
                                }
                              }}
                              labels={() => null}
                              data={ this.state.bm }
                            />

                            <VictoryScatter
                              domain={ {y : this.state.domain } }
                              size={ ({datum})=> Math.min( Math.max(datum.actions.length, 1) , 10)   }
                              labels={({ datum }) => service.formatDate(datum.date) + " \n" +  Math.round(datum.rate * 1000)/1000 + "% (" + datum.actions.length  + ")" }
                              labelComponent={
                                <VictoryTooltip 
                                  flyoutStyle={{ fill : colors.gnTextColor }}
                                  style={ { fill: colors.gnTextColorContrast  }  }
                                  cornerRadius={ 1 } />
                              }
                              style={{
                                data: {
                                  fill:  ({datum})=>((datum.date===this.state.showDate)?colors.gnFrameColor3:((!datum.overdraft)?colors.gnBadgeColor:colors.gnRedColor)+"00") ,
                                  stroke: ({datum})=>  ((datum.date===this.state.showDate)?colors.gnFrameColor3:((!datum.overdraft)?colors.gnBadgeColor:colors.gnRedColor)+"AA") ,
                                  fillOpacity: 1,
                                  strokeWidth: ({datum})=> (datum.date===this.state.showDate)?5:1 ,
                                  zIndex: ({datum})=> (datum.date===this.state.showDate)?10:1 ,
                                } ,
                              }}
                              data={this.state.data} 
                              x={ (datum) =>datum.date  }
                              y={ (datum) =>datum.rate  }
                              events={[{
                                  target: "data",
                                  eventHandlers: {
                                    onClick: (e) => {
                                      return [
                                        {
                                          target: "data",
                                          mutation: (props) => {
                                            const fill = props.style && props.style.fill;
                                            if( this.state.showDate === props.datum.date ) {
                                              this.setState( {showDate : null} )
                                              return null
                                            } else {
                                              this.setState( {showDate : props.datum.date} )
                                              return { style: { fill: colors.gnFrameColor2, stroke: colors.gnFrameColor2,strokeWidth: 5 } };
                                            }
                                            
                                          }
                                        }
                                      ];
                                    },
                                  }
                              }]}
                            />
                          </VictoryChart>
                        }
                        { (this.state.selected.key !== 'symbol') && 
                          <div className={ ['Legends'] }>
                            <div className={ ['L-Holder'] }> 
                              <div className={ ['L-Circle', 'Botfolio'].join(" ") }></div>
                              <div className={ ['L-Name'] }> { (this.props.group)?this.props.group.name:"Portfolio" } </div>
                            </div>
                            {
                              /*
                            <div className={ ['L-Holder'] }> 
                              <div className={ ['L-Circle', 'Brenchmark'].join(" ") }></div>
                              <div className={ ['L-Name'] }> { this.state.branchmark.name.replace("^", "")  } </div>
                            </div>
                            */
                            }
                            <DropdownButton className={["L-Dropdown"].join(' ')} title={ 
                                <div className={ ['L-Holder'] }> 
                                  <div className={ ['L-Circle', 'Brenchmark'].join(" ") }></div>
                                  <div className={ ['L-Name'] }> { this.state.branchmark.name.replace("^", "")  } </div>
                                </div>
                              } 
                            >
                              {
                                this.state.bm_list.map( (v,k) => {
                                  //console.log(v)
                                  return (
                                    <Dropdown.Item 
                                      as="button" 
                                      key={k}
                                      onClick={ (e)=>{  
                                        try {
                                          var _dates = []
                                          for (var i in this.state.dates) {
                                            _dates.push(  service.formatDate(  this.state.dates[i]  )   )
                                          }
                                          this.getBMPrice( v.key , _dates, this.state.backtest_report, this.state.objt )
                                          }
                                        catch(e) {

                                        }
                                      }} > 
                                      { v.name } 
                                    </Dropdown.Item>
                                  )
                                } )
                                
                              }
                            </DropdownButton>
                          </div>
                        }
                        {
                          (this.state.selected.key === 'symbol') && ( this.state.symData.length > 0 ) &&
                            <VictoryChart 
                              padding={ { top: fix, bottom: fix, left: fix, right : fix } }
                              width={ this.state.w  }
                              height={ this.state.h }
                              containerComponent={
                              <VictoryZoomContainer
                                responsive={true} 
                                zoomDimension="x"
                              />
                            }>
                              <VictoryAxis 
                                domainPadding={{x: [0, 100]}}
                                tickFormat={ (t)=>service.formatDate( t )  }
                                style={{
                                  axis: { 
                                    strokeWidth: 1, 
                                    strokeDasharray: 1,
                                    stroke: (colors.gnTextColor+"30")
                                  },
                                  ticks: { padding: 0 },
                                  axisLabel : { 
                                    padding: (this.state.w>0)?-15:0,
                                    fontSize: 9,
                                    fontFamily: colors.gnFont,
                                    fill : colors.gnTextColor,
                                  },
                                  tickLabels: {
                                    fontSize: 9,
                                    fontFamily: colors.gnFont,
                                    fill : colors.gnTextColor,
                                    fillOpacity: 1,
                                    margin: 0,
                                    padding: 0
                                  },
                                }}
                              />
                              <VictoryAxis dependentAxis  
                                domainPadding={{ y:[0, 1000] }}
                                label="Price"
                                style={{
                                  axis: { 
                                    strokeWidth: 0,
                                  },
                                  ticks: { padding: -10 },
                                  grid: {
                                    stroke: ({tick}) => (tick > 0)?(colors.gnGreenColor+"60"):(colors.gnRedColor+"60"), 
                                    strokeDasharray: 5,
                                  },
                                  axisLabel: {
                                    padding: (this.state.h>0)?-20:0,
                                    fontSize: 9,
                                    fontFamily: colors.gnFont,
                                    fill : colors.gnTextColor,
                                  },
                                  tickLabels: {
                                    fontSize: 9,
                                    fontFamily: colors.gnFont,
                                    fill : colors.gnTextColor,
                                    fillOpacity: 1,
                                    margin: 0,
                                    padding: 0
                                  },
                                }}
                              />
                              <VictoryCandlestick
                                animate={{
                                  duration: 2000,
                                  onLoad: { duration: 1000 }
                                }}
                                candleWidth={5}
                                candleColors={{ positive: "#5f5c5b", negative: "#c43a31" }}
                                data={this.state.symData}
                              />
                              { this.state.points.map( (v,k) => {
                                return(
                                  [ 
                                      <VictoryLine
                                        key={k + "_0"}
                                        style={{
                                          data: { stroke: (v.profit>0)?colors.gnGreenColor:colors.gnRedColor },
                                          parent: { border: "1px solid #ccc"},
                                          labels: {
                                            fontSize: 15,
                                            fill : colors.gnTextColor
                                          }
                                        }}
                                        labels={({ datum }) => this.props.gi18n[this.props.language]['porfit_name'].replace( "<profit>" , Math.round(v.profit) ) }
                                        data={ v.data }
                                      />,
                                      <VictoryScatter
                                        key={k + "_1"}
                                        labels={ ({datum})=>"$"+datum.y    }
                                        labelComponent={
                                          <VictoryTooltip 
                                            flyoutStyle={{ fill : colors.gnBcolors }}
                                            style={ { fill: colors.gnTextColor  }  }
                                            cornerRadius={ 1 } />
                                        }
                                        style={{
                                          data: {
                                            fill: (v.profit>0)?colors.gnGreenColor:colors.gnRedColor  ,
                                            stroke: (v.profit>0)?colors.gnGreenColor:colors.gnRedColor ,
                                            fillOpacity: 1,
                                          } ,
                                        }}
                                        data={v.data} 
                                      />
                                    ]
                                )
                              } )
                              }
              
                            </VictoryChart>
                        }

                        { (this.state.selected.key === 'symbol') && ( this.state.symData.length === 0 ) &&
                          <div className={ ['reading'].join(' ') }>
                              <div className={ ['spin'].join(' ') }>
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="secondary" />
                                <Spinner animation="grow" variant="success" />
                                <Spinner animation="grow" variant="danger" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="info" />
                                <Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="dark" />
                              </div>
                              <div className={ ['text'].join(' ') }> { this.props.gi18n[this.props.language]['loading'] }  </div>

                          </div>
                        }

                      </div>
                    </div>

                    <div className={['Middle'].join(" ")}> 
                      <div className={['ST-Holder'].join(" ")}> 
                        <div className={['ST-Title'].join(" ")} >
                          {  this.props.gi18n[this.props.language]['profit_by_strategy']  }
                        </div>
                        <div className={['ST-Wrapper'].join(" ")} >
                            <div className={['ST-Bar-Holder'].join(" ")}>
                              <div className={['ST-Line'].join(' ') } style={ {top : (this.state.strange.dyd/this.state.strange.l*100) + "%" } }>  </div>
                              {
                                this.state.strategy.map( (v,k) => {
                                  var sobj={}
                                  sobj.height = (  Math.abs(v.profit)/this.state.strange.l*100) + "%"
                                  sobj.width = (1/(this.state.strategy.length) * 100) + "%"
                                  if( v.profit >= 0 ) {
                                    sobj.top = (100- ((this.state.strange.dyu+v.profit)/this.state.strange.l*100)) + "%"
                                    sobj.bottom = (this.state.strange.dyu/this.state.strange.l*100) + "%"
                                    sobj.backgroundColor = ((this.state.selected.key==='group')&&(this.state.selected.value===v.group))?colors.gnFrameColor:colors.gnGreenColor
                                    sobj.borderTopLeftRadius = "0.25rem"
                                    sobj.borderTopRightRadius = "0.25rem"
                                  } else {
                                    sobj.top = (this.state.strange.dyd/this.state.strange.l*100) + "%"
                                    sobj.backgroundColor = ((this.state.selected.key==='group')&&(this.state.selected.value===v.group))?colors.gnFrameColor:colors.gnRedColor
                                    sobj.borderBottomLeftRadius = "0.25rem"
                                    sobj.borderBottomRightRadius = "0.25rem"
                                  }
                                  return(
                                    [
                                    <OverlayTrigger
                                        key={k}
                                        placement="bottom"
                                        overlay={<Tooltip>
                                          <div>{"$" + Math.round(v.profit)}</div>
                                          <div>{  this.props.gi18n[this.props.language]['sold_hand'] + " :  " + v.sold + " " + this.props.gi18n[this.props.language]['hold_hand'] + " : " + v.onhold} </div>
                                        </Tooltip>}>
                                        <div
                                          onClick={ (e)=>this.sortdata( 'group' , v.group  )  } 
                                          className={['ST-Bar'].join(" ")} style={ sobj }> </div>
                                    </OverlayTrigger>,
                                    ]

                                  )
                                } )
                              }
                            </div>
                            
                            <div className={['ST-Group'].join(" ")}>
                            {
                              this.state.strategy.map( (v,k) => {
                                var sobj={}
                                sobj.width = (1/(this.state.strategy.length) * 100) + "%"
                                return(
                                  <OverlayTrigger
                                        key={k}
                                        placement="top"
                                        overlay={
                                        <Tooltip>
                                          <div>{"$" + Math.round(v.profit)}</div>
                                          <div>{this.props.gi18n[this.props.language]['sold_hand'] + " :  " + v.sold + " " + this.props.gi18n[this.props.language]['hold_hand'] + " : " + v.onhold} </div>
                                        </Tooltip>
                                        }>
                                      <div
                                        onClick={ (e)=>this.sortdata( 'group' , v.group  )  }  
                                        className={['ST-Group-Name'].join(" ")} style={sobj}> { this.state.snames[ v.group ] } </div>
                                  </OverlayTrigger>
                                )
                              } )
                            }
                            </div>

                        </div>
                      </div>



                      <div className={['Flow-Holder'].join(" ")}> 
                        <div className={['H-Title'].join(" ")}> { this.props.gi18n[ this.props.language]['cash_sotck_on_hold'] }  </div>
                        <div className={['Chart'].join(" ")}> 
                          { this.state.onstock.length > 0 &&
                          <VictoryStack
                            padding={ { top: 0, bottom: 0, left: 0, right : 0 } }
                            width={ this.state.w*0.6  }
                            height={ 100 }
                          >
                            <VictoryBar
                              style={{
                                data: { fill: colors.gnFrameColor+"99" },
                                labels: {
                                  fontSize: 9,
                                  fill : colors.gnTextColor
                                }
                              }}
                              data={ this.state.onstock}
                              labels={({ datum }) =>( service.formatDate(datum.x)+"\n$"+Math.round(datum.y) ) }
                              labelComponent={
                                <VictoryTooltip 
                                  flyoutStyle={{ fill : colors.gnDarkColor }}
                                  style={ { fill: colors.gnTextColor  }  }
                                  cornerRadius={ 1 } />
                              }
                            />
                            <VictoryBar
                              style={{
                                data: { fill: ({datum})=>((datum.y>0)?(colors.gnAccentColor+"99"):colors.gnRedColor) },
                                labels: {
                                  fontSize: 9,
                                  fill : colors.gnTextColor
                                }
                              }}
                              data={ this.state.cash }
                              labels={({ datum }) =>( service.formatDate(datum.x)+"\n$"+Math.round(datum.y) ) }
                              labelComponent={
                                <VictoryTooltip 
                                  flyoutStyle={{ fill : colors.gnDarkColor }}
                                  style={ { fill: colors.gnTextColor  }  }
                                  cornerRadius={ 1 } />
                              }
                            />
                          </VictoryStack>
                            }
                        </div>
                        <div className={['Legend'].join(" ")}> 
                          <div className={ ['Legend-Holder'] }> 
                            <div className={ ['Legend-Circle', 'Cash'].join(" ") }></div>
                            <div className={ ['Legend-Name'] }> { this.props.gi18n[ this.props.language][ "cash" ]  } </div>
                          </div>
                          <div className={ ['Legend-Holder'] }> 
                            <div className={ ['Legend-Circle', 'Onhold'].join(" ") }></div>
                            <div className={ ['Legend-Name'] }> { this.props.gi18n[ this.props.language][ "volume_on_stock" ] } </div>
                          </div>
                        </div>

                      </div>



                    </div>

                    <div className={['Lower'].join(" ")}> 
                  
                      <div className={['SHolder'].join(" ")} >
                        <div className={['STitle'].join(" ")} >
                          { this.props.gi18n[this.props.language]['most_gains'] + " (" + this.state.symbolpw.length + ")"  }
                        </div>
                        {
                          this.state.symbolpw.map( (v,k) => {
                            var sobj = {}
                            sobj.width = (Math.abs(v.profit)/this.state.srange.l*100) + "%"
                            sobj.left = "0px"
                            sobj.backgroundColor = ((this.state.selected.key==='symbol')&&(this.state.selected.value===v.symbol))?colors.gnFrameColor:colors.gnGreenColor
                            return(
                              <div key={k} className={['B-Holder'].join(" ")} > 
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                                  <div
                                    onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }  
                                    className={['B-Symbol'].join(" ")} style={{left:"0px"}}>
                                      {v.symbol}
                                      <span className={['B-Name'].join(" ")}> { (v.symbol in this.props.sym_names)?(this.props.sym_names[v.symbol][ this.props.language ]):"" } </span>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top-start"
                                  overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                                  <div className={['B-Bar-Holder'].join(" ")}>
                                    <div
                                      onClick={ (e)=>{
                                        this.sortdata( 'symbol' , v.symbol  ) 
                                      } }   
                                      className={['B-Bar'].join(" ")} style={sobj}></div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )
                          } )
                        }
                      </div>

                      <div className={['SHolder'].join(" ")} >
                        <div className={['STitle'].join(" ")} >
                          { this.props.gi18n[this.props.language]['most_losses'] + " (" + this.state.symbolpl.length + ")"  }
                        </div>
                        {
                          this.state.symbolpl.map( (v,k) => {
                            var sobj = {}
                            sobj.width = (Math.abs(v.profit)/this.state.srange.l*100) + "%" 
                            sobj.right = "0px"
                            sobj.backgroundColor = ((this.state.selected.key==='symbol')&&(this.state.selected.value===v.symbol))?colors.gnFrameColor:colors.gnRedColor
                            //console.log( this.props.sym_names )
                            return(
                              <div key={k} className={['B-Holder'].join(" ")} > 
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                                  <div
                                    style={{right:"0px"}}
                                    onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }  
                                    className={['B-Symbol', "Neg"].join(" ")}>
                                      {v.symbol}
                                      <span className={['B-Name'].join(" ")}> { (v.symbol in this.props.sym_names)?(this.props.sym_names[v.symbol][ this.props.language ]):"" } </span>
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top-end"
                                  overlay={<Tooltip>{ "$" + Math.round(v.profit)+ " (" + v.quantity + ")" }</Tooltip>}>
                                  <div className={['B-Bar-Holder', 'Neg'].join(" ")}>
                                    <div
                                      onClick={ (e)=>this.sortdata( 'symbol' , v.symbol  )  }   
                                      className={['B-Bar'].join(" ")} style={sobj}></div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )
                          } )
                        }
                      </div>


                    </div>
                  </div>
                </div>


                <div className={['Body', this.state.selectedTab==="log"?'Selected':""].join(" ")}>
                  
                  { (this.state.selectLog === "nested") &&
                    <div className={['Table'].join(" ")}>
                      <Table striped hover responsive variant="dark">
                        <thead>
                          <tr>
                            <td>{ this.props.gi18n[this.props.language]['t_date'] }</td>
                            <td>{ "--" }</td>
                            <td>{ "--" }</td>
                            <td>{ "--" }</td>
                            <td>{ this.props.gi18n[this.props.language]['t_profit'] }</td>
                            <td>{ this.props.gi18n[this.props.language]['t_actions'] }</td>
                          </tr>
                        </thead>
                        <tbody>
                          { 
                            this.state.r_data.map( (v,k) => { 
                              var keys = ['date', 'symbol', 'price', 'decision', 'profit', 'actions']
                              return(
                                [
                                  <tr key={k+"_1"}  className={[ (this.state.showDate===v.date)?'selected':""  ].join(" ")}  onClick={
                                    (e)=>{
                                      if( this.state.showDate === v.date ) {
                                        this.setState( { showDate : null  } )
                                      } else {
                                        this.setState( { showDate : v.date  } )
                                      }
                                    }
                                  }>
                                    { keys.map( (v1, k1 ) => {
                                        var val = ""
                                        var sobj = {}
                                        if( v1 === 'actions' ) {
                                          val = v[v1].length
                                        } else if( v1 === 'date' ) {
                                          val = service.formatDate( v[v1].replace("T", " ").replace("Z", "") )
                                        } else if( v1 === 'profit' ) {
                                          val = Math.round( v[v1] * 1000 )/1000

                                          if( v.dividends ) {
                                            val += v.dividends
                                          }

                                          if( v[v1] > 0 ) {
                                            sobj.backgroundColor = colors.gnGreenColor + "77"
                                            sobj["--bs-table-bg-type"]=colors.gnGreenColor + "77"
                                            sobj.color = colors.gnTextColor
                                          } else if( v[v1] < 0 ) {
                                            sobj.backgroundColor = colors.gnRedColor + "77"
                                            sobj["--bs-table-bg-type"]=colors.gnRedColor + "77"
                                            sobj.color = colors.gnTextColor
                                          } 
                                        } else {
                                          val = "--"
                                        }
                                        return(
                                          <td key={k1} style={ sobj }  >
                                            { val  }
                                          </td>
                                        )
                                    } )
                                    }
                                  </tr>
                                  ,
                                  [ (this.state.showDate===v.date)?
                                      <tr key={k+ "_2"} colSpan="5" className={ [ 'D-Record', 'show' ].join(' ') }  style={{ backgroundColor : colors.gnBadgeColor+"87", color : colors.gnTextColor }}> 
                                        <td>{ this.props.gi18n[this.props.language]['t_symbol'] }</td>
                                        <td>{ this.props.gi18n[this.props.language]['sname'] }</td>
                                        <td>{ this.props.gi18n[this.props.language]['t_price'] }</td>
                                        <td>{ this.props.gi18n[this.props.language]['t_decision'] }</td>
                                        <td>{ this.props.gi18n[this.props.language]['t_profit'] }</td>
                                        <td>{ this.props.gi18n[this.props.language]['t_boardlot'] }</td>
                                      </tr>:null,
                                      (this.state.showDate===v.date)?this.generateChild(v, k):null
                                  ]
                                ]
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                  }

                  { (this.state.selectLog === "listed") &&
                    <div className={['Table'].join(" ")}>
                        <Table striped hover responsive variant="dark">
                          <thead>
                            <tr>
                              <td>{ this.props.gi18n[this.props.language]['t_date'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_symbol'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['sname'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_price'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_volume'] }</td>
                              <td>{ this.props.gi18n[this.props.language]['t_profit'] }</td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.records.sort( (u,v) => { return new Date(v.date) - new Date(u.date) } ).map(  (v,k) => {
                                var keys = [ 'date' , 'symbol', 'sname', 'price', 'decision' ,'profit']
                                return(
                                  <tr key={k+"_1"}  className={[   ].join(" ")}>
                                    { keys.map( (v1, k1 ) => {
                                        var val = "--"
                                        var sobj = {}
                                        if( v1 === 'date' ) {
                                          val = service.formatDate( v[v1] )
                                        } else if( v1 === 'sname' ) {
                                          val = (v['symbol'] in this.props.sym_names)?this.props.sym_names[ v['symbol']  ][this.props.language]:""
                                        } else if( (v1 === 'profit') || (v1 === 'price') ) {
                                          val = Math.round( v[v1] * 1000 )/1000
                                          if( v1 === 'profit' ) {
                                            if( v[v1] > 0 ) {
                                              sobj.backgroundColor = colors.gnGreenColor + "77"
                                              sobj["--bs-table-bg-type"]=colors.gnGreenColor + "77"
                                              sobj.color = colors.gnTextColor
                                            } else if( v[v1] < 0 ) {
                                              sobj.backgroundColor = colors.gnRedColor + "77"
                                              sobj["--bs-table-bg-type"]=colors.gnRedColor + "77"
                                              sobj.color = colors.gnTextColor
                                            }
                                          }
                                        } else if ( v1 === 'decision'  ) {
                                          val = Math.round( v['price'] * v['decision'] * v['boardlot'] * 1000 )/1000
                                          if( val > 0 ) {
                                            sobj.color = colors.gnGreenColor + "FF"
                                          } else if( val < 0 ) {
                                            sobj.color = colors.gnRedColor + "FF"
                                          }
                                          
                                          if( v[v1] === 0 ) {
                                            //val = "Dividends : $" + v.dividends + "/share"
                                            val = this.props.gi18n[this.props.language]['t_dividends'].replace("<dividends>", v.dividends)
                                          } else {
                                            val = ( this.props.gi18n[ this.props.language ][  (val>0)?'t_buy':"t_sell" ] + " : $" + Math.abs(val)  )
                                          }
                                        } else {
                                          val = v[ v1 ]
                                        }
                                        return(
                                          <td key={k1} style={ sobj }  >
                                            { val  }
                                          </td>
                                        )
                                    } )
                                    }
                                  </tr>

                                )
                              } )
                            }
                          </tbody>
                        </Table>
                    </div>
                  }
                  

                </div>

          </Modal.Body>
        }
            
        { this.state.showConfirm && 
            <div className={ ['fade modal show', "Confirm-Frame"].join(" ") }>
              <div className={ ['backdrop'].join(" ") } onClick={
                (e)=>{
                  this.setState({ showConfirm : false, body : null })
                }
              }></div>
              <ModalDialog
                dialogClassName={"Confirm-Dialog"}
                backdropClassName={"Confirm-Backdrop"}
                centered={true}
                className={ ['Confirm', "modal-dialog", "modal-lg", "modal-dialog-centere" ].join(' ') } >
                <Modal.Header closeButton onHide={(e)=>this.setState({ showConfirm : false, body : null })}>
                  <Modal.Title>{ (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['save_record']:"Do you want to sumbit report summary?" }</Modal.Title>
                </Modal.Header>
                { this.state.body &&
                  <Modal.Body className={['error'].join(' ')}>
                    { this.state.body }
                  </Modal.Body>
                }
                <Modal.Footer>
                  <Button variant="primary" onClick={ (e)=>this.saveReport()  }>
                    { (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['submit']:'Submit'  }
                  </Button>
                  <Button variant="secondary" onClick={ (e)=>this.exit() }>
                    { (this.props.language in this.props.gi18n)?this.props.gi18n[this.props.language]['cancel']:"Cancel" }
                  </Button>
                </Modal.Footer>
              </ModalDialog>
            </div>
        }

        

      </Modal>
    );
  }
}



