import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import colors from './public/color.js'


setTimeout(
    () => {
        function addStyle(styleString) {
            var meta = window.config.meta?window.config.meta:"garlican"
            console.log( `Theme - ${meta.toUpperCase()}` )
            const style = document.createElement("style");
            style.textContent = styleString;
            document.head.append(style);
        }
        
        function hexToRgbA(hex){
            var c;
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                c= hex.substring(1).split('');
                if(c.length== 3){
                    c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c= '0x'+c.join('');
                return [(c>>16)&255, (c>>8)&255, c&255].join(',');
            }
            throw new Error('Bad Hex');
        }

        if( window.colors ) {
            for( var i in window.colors["dark"]) {
                colors[ i ] = window.colors["dark"][i]
            }
        }        
        addStyle(`
            :root {
            --gn-bgcolor: ${hexToRgbA(colors.gnBgColor)};
            --gn-black-color: ${hexToRgbA(colors.gnBlackColor)};
            --gn-dark-color: ${hexToRgbA(colors.gnDarkColor)};
            --gn-grey-color: ${hexToRgbA(colors.gnGreyColor)};
            --gn-white-color: ${hexToRgbA(colors.gnWhiteColor)};
            --gn-light-color: ${hexToRgbA(colors.gnLightColor)};
            --gn-green-color : ${hexToRgbA(colors.gnGreenColor)};
            --gn-red-color: ${hexToRgbA(colors.gnRedColor)};
            --gn-darker-color: ${hexToRgbA(colors.gnDarkerColor)};
            --gn-frame-color: ${hexToRgbA(colors.gnFrameColor)};
            --gn-frame-color-2: ${hexToRgbA(colors.gnFrameColor2)};
            --gn-frame-color-3: ${hexToRgbA(colors.gnFrameColor3)};
            --gn-frame-color-4: ${hexToRgbA(colors.gnFrameColor4)};
            --gn-accent-color: ${hexToRgbA(colors.gnAccentColor)};
            --gn-accent-color-2 : ${hexToRgbA(colors.gnAccentColor2)};
            --gn-accent-color-3: ${hexToRgbA(colors.gnAccentColor3)};
            --gn-badge-color: ${hexToRgbA(colors.gnBadgeColor)};
            --gn-white-color: ${hexToRgbA(colors.gnWhiteColor)};

            --gn-text-color: ${hexToRgbA(colors.gnTextColor)};
            --gn-text-color-contrast: ${hexToRgbA(colors.gnTextColorContrast)};
            }
        `);
        //https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
        const container = document.getElementById('root');
        const root = createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(<App tab="home" />);
    }, 500
)

//const container = document.getElementById('root');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript
//root.render(<App tab="home" />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
