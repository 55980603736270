const ai = {
  
  url : "https://finlang.garlican.com",

  "filter" : async (filters, region) => {
    var url = `${"https://lambda.garlican.com/api/"}filter`
    var form = { 
      filter : filters, 
      report_type: "all",
      symbol: [],
      full : true,
      //table: "summaryprofile",
      table : filters['key'],
      region: region,
    }
    try {
      let res = await fetch(
          url, 
          {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify( form ),
          }
      )
      return res.json()
    } catch(e) {
      return { "msg" : "error" }
    }
  },

  /* FIN LANG */
  "request" : async(path, method="GET", body=null, multipart=false) => {
    const headers = {
      "Authorization": "Bearer 1fPUuPhgGdyZbiXb5n9c"
    }
    const params = {
      method : method,
      headers : headers,
    }
    if(body) {
      if(  !multipart ) {
        params['body'] = JSON.stringify( body )
        //params['headers']["content-type"] = "application/json"
        //params['headers']["accept-content"] = "application/json"
      } else {
        params['body'] = body
        //params['headers']["content-type"] = "multipart/form-data"
      }
    }
    const url = `${ai.url}${path}`

    try {
      let res = await fetch(
        url, 
        params
      )
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  "analyze"  : async (body)=>{
    let data = await ai.request("/action", "POST", body)
    return data
  },

  "ai_botfolio" : async(body) => {
    //aibotfolio
    let data = await ai.request("/aibotfolio", "POST", body)
    return data
  },

  "aiimagescan" : async(body) => {
    let data = await ai.request("/aiimagescan", "POST", body, true)
    return data
  },

  "aiimage" : async(body) => {
    let data = await ai.request("/aiimage", "POST", body, true)
    return data
  },

  "screener" : async(body) => {
    //aibotfolio
    let data = await ai.request("/screener", "POST", body)
    return data
  },

  "backtest" : async(body) => {
    let data = await ai.request("/backtest", "POST", body)
    return data
  },

  "forwardtest" : async(body) => {
    let data = await ai.request("/forwardtest", "POST", body)
    return data
  },



  /* FIN LANG */




  /*INTERERAL FUNCTIONS*/

  "tempGroup" : (code, filter_obj) => {
    var obj = {
      "information":{"country":"USA","exchange":"NASDAQ","start_date":"20240101","end_date":"20240512","resolution":"d1"},
      "params":{"transaction_fee":0,"leverage":1,"boardlot":100,"scale":60000000000,"initial":100000,"safety":0.3},
      "group":{"id":"G_ZB49L6Z8R4ZIFF103BPFM","name":"New Botfolio_1702823636","initial_capital":10000,"transaction_fee":2,"description":"","partition":{"194":50},"created_at":"2023-12-17T14:33:56.423Z","input":{},"region":"usa","backtest_report":null,"forwardtest_report":null,"profit_rate":0,"sharpe_ratio":0,"win_rate":0,"access_mode":"private","last_modified":"2024-05-23T14:33:56.423Z",
        "strategy_list":[
          {
            "id":194,"user_id":3,"name":"MACD Diverge Test","description":"About this template.","creation_time":"2022-08-13 19:02:18","modification_time":"2023-04-10 15:10:51","input":{},"access_mode":"public","region":"usa",
            "code":code,"filters":filter_obj
          }
      ]},
      "demo":false,"include_dividends":true,"region":"usa"
    }
    return obj
  },

  "isNumeric" : (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

}

export default ai;