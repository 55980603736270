import React from "react";
import './index.scss'

import Lottie from 'react-lottie';

export default class Cover extends React.Component {

  constructor(props) {
    super(props);  

    this.state = {
      logo : null
    } 

    this.init = this.init.bind(this)

  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
    if( props.thememode !== this.props.thememode ) {
      this.init()
    }
  }

  async init() {
    const {thememode} = this.props
    let data = await fetch(window[`lotties_logofull_${thememode}`] )
    const res = await data.json()
    this.setState({
      logo : res
    })
  }

  render() {
    const { i18n, language, create, thememode } = this.props
    const { templates } = window
    const {logo} = this.state
    //console.log( window.templates )

    return (
      <div className={["I-Cover"].join(" ")}>
        { !logo &&
          <img src={window[`logofull_${thememode}`]} className={["IDE-Logo"].join(" ")}/>
        }
        {
          logo &&
          <Lottie 
            className={["L-Logo"].join(" ")}
            options={{
              loop: true,
              autoplay: true, 
              animationData: logo,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={100}
            width={"auto"}/>
        }
        <div className={ ["S-Holder"].join(" ") } >
          <span>{"Pick a strategy to play arround"}</span>
        </div>
        <div className={ ["I-Container"].join(" ") }>
          {
            templates.map(
              (v,k) => {
                return (
                  <div className={ ["Holder"].join(" ") } key={k} onClick={()=>create(v['prompt'][language])}>
                    <div className={ ["Name"].join(" ") }>{ v['title'][language] }</div>
                    <div className={ ["Badge-Holder"].join(" ") }> 
                      {
                        v['keywords'].map( (val, key) => {
                          return (
                            <div className={ ["Badge"].join(" ") } key={key}> { val }</div>
                          )
                        })
                      }
                    </div>
                    <div className={ ["Prompt"].join(" ") }>{ v['prompt'][language] }</div>
                  </div>
                )
              }
            )
          }
        </div>
      </div>
    )
  }
}


