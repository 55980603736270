import "ace-builds/src-noconflict/snippets/python";
import GService from "../../../Garlican/GarlicanLibrary/gservice";

export class CustomHighlightRules extends window.ace.acequire("ace/mode/text_highlight_rules").TextHighlightRules {
	constructor() {
		super();

		var k = "as|import|and|break|continue|elif|else|for|from|if|in|not|or|pass|" + GService.defaults.join("|") + "|" + GService.exit.join("|")

		var keywords = k
	
		var builtinConstants = (
			"True|False|None"
		);
	
		var f = []
		for( var i in  window.func) {
			if(  window.func[i].display ) {
				f.push(  window.func[i].name  )
			}
		}

		var builtinFunctions = f.join("|")

		var keywordMapper = this.createKeywordMapper({
			"invalid.deprecated": "debugger",
			"support.function": builtinFunctions,
			"variable.language": "self|cls",
			"constant.language": builtinConstants,
			"keyword": keywords
		}, "identifier");
	

		var strPre = "[uU]?";
		var strRawPre = "[rR]";
		var strFormatPre = "[fF]";
		var strRawFormatPre = "(?:[rR][fF]|[fF][rR])";
		var decimalInteger = "(?:(?:[1-9]\\d*)|(?:0))";
		var octInteger = "(?:0[oO]?[0-7]+)";
		var hexInteger = "(?:0[xX][\\dA-Fa-f]+)";
		var binInteger = "(?:0[bB][01]+)";
		var integer = "(?:" + decimalInteger + "|" + octInteger + "|" + hexInteger + "|" + binInteger + ")";
	
		var exponent = "(?:[eE][+-]?\\d+)";
		var fraction = "(?:\\.\\d+)";
		var intPart = "(?:\\d+)";
		var pointFloat = "(?:(?:" + intPart + "?" + fraction + ")|(?:" + intPart + "\\.))";
		var exponentFloat = "(?:(?:" + pointFloat + "|" + intPart + ")" + exponent + ")";
		var floatNumber = "(?:" + exponentFloat + "|" + pointFloat + ")";
	
		var stringEscape = "\\\\(x[0-9A-Fa-f]{2}|[0-7]{3}|[\\\\abfnrtv'\"]|U[0-9A-Fa-f]{8}|u[0-9A-Fa-f]{4})";
		this.$rules = {
			"start" : [ {
				token : "comment",
				regex : "#.*$"
			}, {
				token : "string",           // multi line """ string start
				regex : strPre + '"{3}',
				next : "qqstring3"
			}, {
				token : "string",           // " string
				regex : strPre + '"(?=.)',
				next : "qqstring"
			}, {
				token : "string",           // multi line ''' string start
				regex : strPre + "'{3}",
				next : "qstring3"
			}, {
				token : "string",           // ' string
				regex : strPre + "'(?=.)",
				next : "qstring"
			}, {
				token: "string",
				regex: strRawPre + '"{3}',
				next: "rawqqstring3"
			}, {
				token: "string", 
				regex: strRawPre + '"(?=.)',
				next: "rawqqstring"
			}, {
				token: "string",
				regex: strRawPre + "'{3}",
				next: "rawqstring3"
			}, {
				token: "string",
				regex: strRawPre + "'(?=.)",
				next: "rawqstring"
			}, {
				token: "string",
				regex: strFormatPre + '"{3}',
				next: "fqqstring3"
			}, {
				token: "string",
				regex: strFormatPre + '"(?=.)',
				next: "fqqstring"
			}, {
				token: "string",
				regex: strFormatPre + "'{3}",
				next: "fqstring3"
			}, {
				token: "string",
				regex: strFormatPre + "'(?=.)",
				next: "fqstring"
			},{
				token: "string",
				regex: strRawFormatPre + '"{3}',
				next: "rfqqstring3"
			}, {
				token: "string",
				regex: strRawFormatPre + '"(?=.)',
				next: "rfqqstring"
			}, {
				token: "string",
				regex: strRawFormatPre + "'{3}",
				next: "rfqstring3"
			}, {
				token: "string",
				regex: strRawFormatPre + "'(?=.)",
				next: "rfqstring"
			}, {
				token: "keyword.operator",
				regex: "\\+|\\-|\\*|\\*\\*|\\/|\\/\\/|%|@|<<|>>|&|\\||\\^|~|<|>|<=|=>|==|!=|<>|="
			}, {
				token: "punctuation",
				regex: ",|:|;|\\->|\\+=|\\-=|\\*=|\\/=|\\/\\/=|%=|@=|&=|\\|=|^=|>>=|<<=|\\*\\*="
			}, {
				token: "paren.lparen",
				regex: "[\\[\\(\\{]"
			}, {
				token: "paren.rparen",
				regex: "[\\]\\)\\}]"
			}, {
				token: "text",
				regex: "\\s+"
			}, {
				include: "constants"
			}],
			"qqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "qqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"qstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "qstring"
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqqstring3": [{
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqstring3": [{
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rawqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"rawqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rawqstring"
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				defaultToken: "string"
			}],
			"fqqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstring3": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "\\\\$",
				next: "fqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstring": [{
				token: "constant.language.escape",
				regex: stringEscape
			}, {
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqqstring3": [{
				token: "string", // multi line """ string end
				regex: '"{3}',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqstring3": [{
				token: "string",  // multi line ''' string end
				regex: "'{3}",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqqstring": [{
				token: "string",
				regex: "\\\\$",
				next: "rfqqstring"
			}, {
				token: "string",
				regex: '"|$',
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"rfqstring": [{
				token: "string",
				regex: "'|$",
				next: "start"
			}, {
				token: "paren.lparen",
				regex: "{",
				push: "fqstringParRules"
			}, {
				defaultToken: "string"
			}],
			"fqstringParRules": [{//TODO: nested {}
				token: "paren.lparen",
				regex: "[\\[\\(]"
			}, {
				token: "paren.rparen",
				regex: "[\\]\\)]"
			}, {
				token: "string",
				regex: "\\s+"
			}, {
				token: "string",
				regex: "'(.)*'"
			}, {
				token: "string",
				regex: '"(.)*"'
			}, {
				token: "function.support",
				regex: "(!s|!r|!a)"
			}, {
				include: "constants"
			},{
				token: 'paren.rparen',
				regex: "}",
				next: 'pop'
			},{
				token: 'paren.lparen',
				regex: "{",
				push: "fqstringParRules"
			}],
			"constants": [{
				token: "constant.numeric", // imaginary
				regex: "(?:" + floatNumber + "|\\d+)[jJ]\\b"
			}, {
				token: "constant.numeric", // float
				regex: floatNumber
			}, {
				token: "constant.numeric", // long integer
				regex: integer + "[lL]\\b"
			}, {
				token: "constant.numeric", // integer
				regex: integer + "\\b"
			}, {
				token: ["punctuation", "function.support"],// method
				regex: "(\\.)([a-zA-Z_]+)\\b"
			}, {
				token: keywordMapper,
				regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
			}]
		}
		this.normalizeRules();
	}
}

export default class CustomNode extends window.ace.acequire("ace/mode/python").Mode {
	constructor() {
		super();
		this.HighlightRules = CustomHighlightRules
	}




}


/*
var keywords = (
			"and|as|assert|break|class|continue|def|del|elif|else|except|exec|" +
			"finally|for|from|global|if|import|in|is|lambda|not|or|pass|print|" +
			"raise|return|try|while|with|yield|async|await|nonlocal"
		);
	
		var builtinConstants = (
			"True|False|None|NotImplemented|Ellipsis|__debug__"
		);
	
		var builtinFunctions = (
			"abs|divmod|input|open|staticmethod|all|enumerate|int|ord|str|any|" +
			"eval|isinstance|pow|sum|basestring|execfile|issubclass|print|super|" +
			"binfile|bin|iter|property|tuple|bool|filter|len|range|type|bytearray|" +
			"float|list|raw_input|unichr|callable|format|locals|reduce|unicode|" +
			"chr|frozenset|long|reload|vars|classmethod|getattr|map|repr|xrange|" +
			"cmp|globals|max|reversed|zip|compile|hasattr|memoryview|round|" +
			"__import__|complex|hash|min|apply|delattr|help|next|setattr|set|" +
			"buffer|dict|hex|object|slice|coerce|dir|id|oct|sorted|intern|" +
			"ascii|breakpoint|bytes"
		);
*/