import React from "react";
import './index.scss'

import Panel from "./Panel"
import IDE from "./Ide"
import Simple from "./Simple";
import {FaUser, FaChevronRight, FaChevronLeft} from "react-icons/fa"
import GService from "../../Garlican/GarlicanLibrary/gservice";
import Apis from "../../Garlican/GarlicanLibrary/apis";
import service from "../../public/service"
import GarlicanReport from '../../Garlican/GarlicanReport'
import Loading from "../Loading";
import ai from "../../public/aiempower";

const modes = [
  {"key" : "simple", "value" : "Description"},
  {"key" : "advance", "value" : "Code"},
]

const MAXLENGTH = 150

export default class Editor extends React.Component {

  constructor(props) {
    super(props);  


    this.state = {

      mode : "simple",
      botfolio : [],
      selected : null,
      show : true,
      templates : [],

      showReport : false, 
      report : {},
      isLoading : false,
      favorite_symbol_list : {},
      prompt : ""
    } 

    this.ide = React.createRef()
    this.panel = React.createRef()
    this.simple = React.createRef()
    this.backtest = this.backtest.bind(this)
    this.create = this.create.bind(this)
    this.init = this.init.bind(this)
    this.updatevalue = this.updatevalue.bind(this)
    this.updateObj = this.updateObj.bind(this)
    this.save = this.save.bind(this)
    this.updateGroup = this.updateGroup.bind(this)
    this.createGroup = this.createGroup.bind(this)
    this.deleteGroup = this.deleteGroup.bind(this)
    this.todelete = this.todelete.bind(this)
    this.updateFavoriteList = this.updateFavoriteList.bind(this)
    this.selectedMode = this.selectedMode.bind(this)
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
   if (props.isLogin !== this.props.isLogin) {
      this.init()
   }
  }

  async init() {
    const {user} = this.props
    if( user && user['user_id'] ) {
      let mybotfolio = await service.getPortfoilioList( {user_id : user['user_id']} )
      let templates = await service.getTemplates()
      var obj = {}
      try {
        const fsym = user["description"]?JSON.parse(user["description"]):{}
        obj["favorite_symbol_list"] = fsym
      } catch(e) {
        console.log(e)
      }
      
      try {
        if( mybotfolio['list'].length > 0 ) {
          const bots = mybotfolio['list'].filter((v)=>(v["subscription_id"]===null))
          if( bots.length > 0 ) {
            obj['botfolio'] = bots
          }
        }
        if( templates['list'].length > 0 ) {
          const temp = templates['list'].filter((v)=>(v["access_mode"]==="public"))
          if( temp.length > 0 ) {
            obj['templates'] = temp
          }  
        }
        this.setState(obj)
      } catch(e) {
        console.log(e)
      }
    }
  }

  create(prompt) {
    var gp = GService.createGroupObject( {} , null , "" )
    var data = JSON.parse(JSON.stringify(GService['default_template']))
    //var data = JSON.parse(JSON.stringify(window['templates']['default_template']))
    data['id'] = "temp_id"
    data['enable'] = true
    gp["strategy_list"] = [ data ]
    gp['region'] = "usa"
    data['filters'] = GService.defaultFilter["usa"]
    gp['partition'] = { "temp_id" : "100" }
    this.setState({
      selected : gp,
      mode : "simple",
      prompt : prompt
    })
    if( prompt ) {
      this.simple?.current?.updatevalue({prompt:prompt})
    }
    if( this.panel && this.panel.current ) {
      this.panel.current.init()
    }
  }

  updateObj(props, state) {
    this.setState({
      props : props,
    })
    this.forceUpdate()
  }

  updatevalue(obj, cb) {
    if( "selected" in obj ) {
        if( this.simple.current ) {
          this.simple.current.updatevalue( { valid : true} )
        }
    }

    this.setState( obj, cb )
    //this.forceUpdate()

  }

  save( ) {
    const {mode} = this.state
    if(  this.ide && this.simple ) {
      var code = mode==="advance"?this.ide.current.getCode():this.simple.current.getCode()
      const {selected} = this.state
      selected["strategy_list"][0]['code'] = code    
      if( typeof(selected['id'])==="string" && selected['id'].indexOf("G_") > -1 ) {
        this.createGroup( selected )
      } else {
        this.updateGroup( selected )
      }
    }
  }

  async updateGroup(g) {
    console.log( "UPATES" )
    const {i18n, language} = this.props
    this.setState({ isLoading : true } , async () => {
      let data = await service.updatePortfolio( g['id'] ,  g )
      var s = g['strategy_list'][0]
      s['portfolio_id'] = data['id']
      let strategy = await service.updateStrategy( s['id'], s )
      if( !("id" in strategy) ) {
        alert(i18n[language]["error_save"])
      } else {
        alert( i18n[language]["save_successfuly"] )
        this.init()
      }
      this.setState({ 
        isLoading : false
      })
    })
  }

  async createGroup(g) {
    console.log("CREATE_NEW")
    const {i18n, language} = this.props
    this.setState({ isLoading : true } , async () => {
      let data = await service.createPortfolio( g )
      const pid = data['id']
      if( "id" in data ) {
        var s = g['strategy_list'][0]
        s['portfolio_id'] = pid
        let strategy = await service.createStrategy( s )
        if( !("id" in strategy) ) {
          alert(i18n[language]["error_save"])
        } else {
          g["id"] = pid
          g['partition'] = {}
          g['partition'][ strategy['id'] ] = "100"
          let success = await service.updatePortfolio( pid ,  g )
          alert( i18n[language]["save_successfuly"] )
          this.init()
        }
        this.setState({ 
          isLoading : false
        })
      }
    })
    
  }

  async backtest() {
    var {selected, mode} = this.state
    var code = mode==="advance"?this.ide.current.getCode():this.simple.current.getCode()
    selected["strategy_list"][0]['code'] = code
    selected["strategy_list"][0]["input"] = selected["strategy_list"][0]["input"]?selected["strategy_list"][0]["input"]:{}
    selected['input'] = {}

    //MAXLENGTH
    var syms = selected["strategy_list"][0]["filters"][0]['value'].split(",")
    if( syms.length > MAXLENGTH ) {
      window.alert(`Not More than ${MAXLENGTH} symbol`)
      return 
    }

    this.setState({
      isLoading : true
    }, async () => {
      //let data = await Apis.backtestingS(selected, 20000, selected['region'], true )

      const tempGp = ai.tempGroup("",{})
      tempGp['group'] = selected
      tempGp['region'] = selected['region']

      const body = {
        "group" : tempGp,
        "maxsize" : MAXLENGTH,
        "region" : selected['region']
      }
      try {

        let data = await Apis.backtestingS(selected, 20000, selected['region'], true )
        //console.log( JSON.stringify( tempGp )  )

        //let data = await ai.backtest( body )


        var obj = { isLoading : false}
        try {
          if( data.length > 0 ) {
            obj['report'] = data
            obj['showReport'] = true
          } else {
            window.alert("No Trading Record Found.")
          }
          this.setState( obj )
        } catch(e) {
          console.log(e)
          this.setState( obj )
        }
      } catch(e) {
        this.setState({
          isLoading : false
        }, ()=>{
          window.alert("Error")
        })
      }
      
    })
    

  }

  deleteGroup(g) {
    const {i18n, language} = this.props
    var a = window.confirm( i18n[language]["confirm_remove"] )
    if( a ) {
      this.todelete(g)
    }
  }

  async todelete(g) {
    this.setState( {
      isLoading : true
    }, async () => {
      var s = g['strategy_list'][0]
      let strategy = await service.deleteStrategy( s['id'] )
      let data = await service.deletePortfolio( g['id'] )
      this.init()
      this.setState({
        isLoading : false,
        selected : null
      })
    } )
  }

  async updateFavoriteList(region, symbol) {
    const {updatevalue} = this.props
    var { favorite_symbol_list } = this.state
    var symbols = favorite_symbol_list
    symbols[region] = symbol
    var obj = {
      "description" : JSON.stringify(symbols)
    }
    let res = await service.updateUserProfile( obj )
    if( res['status'] === 200 ) {
      var { user } = this.props
      user['description'] = JSON.stringify(symbols)
      await service.storeData("user", user)
      updatevalue({ user : user }, ()=>{
        this.forceUpdate()
      })
    }
  }

  selectedMode(m) {
    const {mode} = this.state
    var code = mode==="advance"?this.ide.current.getCode():this.simple.current.getCode()
    const {selected} = this.state
    
    if( mode === "simple" ) {
      var description = this.simple.current.getDesc()
      selected["description"] = description
    }
    selected["strategy_list"][0]['code'] = code  
    this.setState({ mode : m, selected:selected })
  }

  render() {
    const { selected, botfolio, templates, isLoading, favorite_symbol_list, mode, prompt } = this.state
    const {i18n, language, sym_names, user, updatevalue, thememode, colors} = this.props
    
    return (
      <div className={[ 'Editor'  ].join(' ')} >
        <div className={[ 'Panel-Wrapper' , !this.state.show?"Hide":"" ].join(' ')} >
          {
            <Panel 
              favorite_symbol_list={favorite_symbol_list}
              updateFavoriteList={ this.updateFavoriteList }
              ref={this.panel}
              backtest={this.backtest}
              updatevalue={this.updatevalue}
              create={this.create}
              deleteGroup={this.deleteGroup}
              botfolio={botfolio}
              i18n={i18n}
              language={language}
              selected={selected} 
              sym_names={sym_names}
              templates={templates}
              user={user}
            />
          }
        </div>

        <div className={[ 'IDE-Wrapper'  ].join(' ')} >
          <div className={[ 'IDE-Control'  ].join(' ')} >
            <div className={ ["IDE-Chevron", "btn-outline", !this.state.show?"Unlimited":""].join(" ") } onClick={ (e) => { this.setState( {show : !this.state.show} )} }>
              {
                !this.state.show && 
                <div className={ ["Name"].join(" ") }>
                  { selected?selected['name']:""  }
                </div>
              }
              {
                this.state.show &&
                <FaChevronLeft className={ [  "Fa" ].join(' ') } />
              }
              {
                !this.state.show &&
                <FaChevronRight className={ [  "Fa" ].join(' ') } />
              }
            </div>
            {
              selected &&
              <div className={"Modes"}>  
                {
                  modes.map( (v,k) => {
                    return (
                      <div 
                        onClick={ ()=>this.selectedMode( v['key'] ) }
                        className={["btn-outline", mode===v.key?"Selected":""].join(" ")} key={k}> { v["value"] } </div>
                    )
                  } )
                }
              </div>
            }

            {
              selected &&
              <select className={"Modes2"} 
                value={ mode }
                onChange={(e)=>{
                  this.selectedMode( e.target.value )
              }}>  
                {
                  modes.map( (v,k) => {
                    return (
                      <option
                        key={k}
                        value={ v['key'] }>
                        { v["value"] } </option>
                    )
                  } )
                }
              </select>
            }


            {
              selected &&
              <div className={ ["Update",  "btn-outline"].join(" ") } onClick={ (e)=> this.save() }>{ i18n[language]["save"].toUpperCase() }</div>
            }
          </div>
          <div className={[ 'IDE-Content'  ].join(' ')} >

            
            {
              mode === "advance" &&
              <IDE 
                {...this.props} 
                ref={ this.ide }
                lists={ this.state.lists } 
                updateEditorvalue={this.updatevalue}
                selected={ this.state.selected } />
            }
            {
              mode === "simple" &&
              <Simple 
                {...this.props} 
                ref={ this.simple }
                lists={ this.state.lists } 
                create={this.create}
                updateEditorvalue={this.updatevalue}
                prompt={prompt}
                selected={ this.state.selected } />
            }

          </div>

        </div>

        { language in i18n && 
					<GarlicanReport 
            colors={colors}
            gi18n={i18n}
            language={language}
            sym_names={sym_names}
            showReport={this.state.showReport} 
            report={this.state.report} 
            group={selected?selected:{}} 
            isOwner={ true } 
            readonly={ false }
            type={"backtest"} 
            save={null}
            editGroup={()=>null}
            updateValue={ this.updatevalue } 
          />
	  		}

        {
          isLoading && <Loading cover={true} thememode={thememode}/>
        }

      </div>
    );
  }
}


